<div class="container">
  <div class="title">AssetWorks Analytics Platform</div>

  <div class="component">Portal</div>

  <table class="portal-version">
    <tr>
      <td width="65px">
        Version:
      </td>
      <td class="version">
        {{portalVersion}}
      </td>
    </tr>
  </table>

  <div class="component">API</div>

  <table class="api-version">
    <tr>
      <td class="heading">
        Assembly
      </td>
      <td class="heading">
        Version
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <hr>
      </td>
    </tr>

    <tr *ngIf="apiComponentVersions.length === 0">
      <td colspan="2">
        Loading...
      </td>
    </tr>

    <tr *ngFor="let component of apiComponentVersions">
      <td>
        {{component.Name}}
      </td>
      <td class="version">
        {{component.Version}}
      </td>
    </tr>
    
  </table>

  <div class="component">Data</div>

  <table class="data">
    <tr>
      <td width="100px">
        Refreshed on
      </td>
      <td (click)="onRefreshDateClick()" class="data-date">
        {{getDataRefreshDate() | shortDateTime}}
      </td>
    </tr>
  </table>
</div>

<app-dataset-viewer></app-dataset-viewer>
import { KeyValue } from '@angular/common';
export class Report {
    public id: number;
    public group: string;
    public title: string;
    public rating: number = 0.0;
    public ratingCount: number = 0;
    public description: string;
    public thumbnail: string;
    public categories: string[];
    public lastViewed: string;
    public powerBi: {
        id: string,
        group: string,
        isTimeBased: boolean,
        url: string
    };
    public menus: number[] = [];
    public filters: Filter[] = [];
    public detailUrl: string;
    public favoriteUrl: string;
    public lastViewUrl: string;
    public filterUrl: string;
    public ratingUrl: string;

    public constructor(init?: Partial<Report>) {
        Object.assign(this, init);
    }
}

export class Rating {
    public id: string;
    public date: string;
    public user: string;
    public customer: string;
    public rating: number;
    public comment: string;
    public allowDelete: boolean;
    public ratingUrl: string;

    public constructor(init?: Partial<Rating>) {
        Object.assign(this, init);
    }
}

export class Filter {
    public id: number = 1;
    public name: string;
    public table: string;
    public column: string;
    public type: string;
    public searchable: boolean;
    public operator: KeyValue<string, string>;
    public values: ReportFilterValueSearch[] = [];
    public filterValueListUrl: string;

    public constructor(init?: Partial<Filter>) {
        Object.assign(this, init);
    }

    public clone() {
        return new Filter({
            id: this.id,
            name: this.name,
            table: this.table,
            column: this.column,
            searchable: this.searchable,
            values: this.values,
            operator: this.operator,
            filterValueListUrl: this.filterValueListUrl
        });
    }
}

export class SavedFilter {
    public id: number;
    public name: string;
    public filters: Filter[] = [];
    public selected: boolean;
    public allowDelete: boolean;
    public default: boolean;
   
    public constructor(init?: Partial<SavedFilter>) {
        Object.assign(this, init);
    }
}

export class ReportDetail {
    public accessToken: string;
    public savedFilters: SavedFilter[] = [];
}

export class ReportFilterValueSearch {
    public id: any;
    public code: string;
    public description: string;

    public constructor(init?: Partial<ReportFilterValueSearch>) {
        Object.assign(this, init);
    }
}
import { StateService } from './state.service';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { TranslationType } from '../models/translation-type';


@Injectable()
export class SettingsService {

  constructor(private api: ApiService,
              private stateService: StateService) { }

  getTranslationTypes() {

    // URL from the assets/config.js
    // @ts-ignore
    const url = SERVICE_URL + '/translation/types';

    return this.api.get(url).then((data)=>{
      return new Promise((resolve, reject) => {
        try
        {
          resolve(data['ProcessOutcome']['TranslationTypes']);
        }
        catch (e)
        {
          reject(e);
        }
      })
    });
  }  

  getTranslationOptions(type: string) {

    // URL from the assets/config.js
    // @ts-ignore
    const url = SERVICE_URL + '/translation/types/' + type;

    return this.api.get(url).then((data)=>{
      return new Promise((resolve, reject) => {
        try
        {
          resolve(data['ProcessOutcome']['TranslationOptions']);
        }
        catch (e)
        {
          reject(e);
        }
      })
    });
  }
  
  getTranslationValues(type: string) {

    // URL from the assets/config.js
    // @ts-ignore
    const url = SERVICE_URL + '/translation/types/' + type + '/values';

    return this.api.get(url).then((data)=>{
      return new Promise((resolve, reject) => {
        try
        {
          resolve(data['ProcessOutcome']['TranslationValues']);
        }
        catch (e)
        {
          reject(e);
        }
      })
    });
  }

  setTranslationValue(type: string, code: string, translation: string) {

    // URL from the assets/config.js
    // @ts-ignore
    const url = SERVICE_URL + '/translation/types/' + type + '/values';

    const body = {
      code: code,
      translation: translation
    }

    return this.api.post(url, body).then((data)=>{
      return new Promise((resolve, reject) => {
        try
        {
          resolve(data['ProcessOutcome']);
        }
        catch (e)
        {
          reject(e);
        }
      })
    });

  }
}

<ng-container *ngIf="stateService.isAuthenticated">
  <mat-drawer-container *ngIf="!stateService.fullScreen" class="drawer-container" autosize>
    <mat-drawer #sidenav mode="side" disableClose="true" opened [ngClass]="{'force-drawer-open': forceSideMenuOpen===true, 
                                                                            'mat-drawer-closed': isMenuOpened===false,
                                                                            'mat-drawer-opened mat-drawer-side': isMenuOpened===true}">
      <app-menu 
        location='side' 
        (toggle)="onMenuToggle($event)" 
        (forceCloseMenu)="onForceMenuClose()" 
        [forceShowMenu]="forceSideMenuOpen"
        [expandCategory]="expandCategory" 
        [(selectedMenu)]="selectedMenu"
        (selectedMenuChange)="onMenuChange($event)">
      </app-menu>
    </mat-drawer>
    <mat-drawer-content class="content-container" [ngClass]="{'content-container-expanded': isMenuOpened===false}">
      <app-banner 
        [menu]="selectedMenu"
        [user]="user"
        [ismenuopen]="isMenuOpened">
      </app-banner>
      
      <router-outlet></router-outlet>
      
      <app-menu
        location='bottom' 
        (forceShowMenuChange)="onForceSideMenuOpen($event)" 
        [(selectedMenu)]="selectedMenu"
        (selectedMenuChange)="onMenuChange($event)">
      </app-menu>
    </mat-drawer-content>
  </mat-drawer-container>

  <router-outlet *ngIf="stateService.fullScreen" name="fullscreen"></router-outlet>
</ng-container>
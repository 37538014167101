import { Customer } from './../../models/customer';
import { StateService } from 'src/app/services/state.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Rating, Report } from 'src/app/models/report';
import { ReportService } from 'src/app/services/report.service';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalService, OpenModalRequest } from 'src/app/services/modal.service';
import { first } from 'rxjs/operators';

interface RatingIndicator {
  id: number;
  active: boolean;
}

@Component({
  selector: 'app-rating-viewer',
  templateUrl: './rating-viewer.component.html',
  styleUrls: ['./rating-viewer.component.scss']
})
export class RatingViewerComponent implements OnInit {

  @ViewChild('commentfield', {static: false}) commentField: ElementRef;

  rating: number = 0.0;
  reviewCount: number = 0;

  ratings: RatingIndicator[] = [
    {
      id: 1,
      active: false
    },
    {
      id: 2,
      active: false
    },
    {
      id: 3,
      active: false
    },
    {
      id: 4,
      active: false
    },
    {
      id: 5,
      active: false
    }
  ]

  reviews: Rating[] = [];
  reviewsClone: Rating[] = [];
  report: Report;

  currentFilter: number = 0;
  ratingsLoaded: boolean = false;

  constructor(private modalService: ModalService,
              private reportService: ReportService,
              private customerService: CustomerService,
              private stateService: StateService) {
                this.modalService.onModalOpen.subscribe((openModalRequest: OpenModalRequest)=>{
                  if (openModalRequest.id === 'app-report-rate-viewer')
                  {
                    if (!this.ratingsLoaded)
                    {
                      this.ratingsLoaded = true;
                      this.rating = openModalRequest.data.rating;
                      // openModalRequest.data will be a Report object
                      this.report = openModalRequest.data;
                      this.getRatings();
                    }
                  }
                });
              }

  ngOnInit() {
    
  }

  getRatings() {
    this.reportService.getReportRatings(this.report).then((reviews: Rating[]) => {

      // clear arrays 
      this.reviews = [];
      this.reviewsClone = [];

      this.reviews = reviews.sort((a, b)=>{

        if (a.date > b.date)
        {
          return -1;
        }

        return 1;
      });

      // Clone reviews for distribution widget
      this.reviewsClone = [...this.reviews];
      this.reviewCount = reviews.length;
    });
  }

  getRatingIcon(rating: RatingIndicator) {
        
    if (rating.id <= this.rating)
    {
      return "star";
    }
   
    if (rating.id - this.rating > 0 && rating.id - this.rating < 1)
    {
      return "star_half";
    }

    return "star_outline";
  }

  onDistributionWidgetClick(id: number) {

    if (id === this.currentFilter)
    {
      this.reviews = this.reviewsClone.filter(r => r.rating > 0);
      this.reviewCount = this.reviews.length;
      this.currentFilter = 0;
      return;
    }

    this.reviews = this.reviewsClone.filter(r => r.rating == id);
    this.reviewCount = this.reviews.length;
    this.currentFilter = id;
  }

  onRemoveReview(rating: Rating) {
    this.reportService.removeReportRating(rating).then(()=> {
      this.getRatings();

      this.customerService.getCustomer().then((c: Customer) => {
        this.stateService.setCustomer(c);
        this.rating = c.reports.find(r => r.id === this.report.id).rating;
        this.stateService.reportReviewRemoved.next(true);
      })
    });
  }

  closeModal() {
    this.ratingsLoaded = false;
    this.modalService.close('app-report-rate-viewer');
  }

  cancel() {
    this.closeModal();
  }
}

import { ModalService } from './../../services/modal.service';
import { StateService } from 'src/app/services/state.service';
import { Report } from './../../models/report';
import { SelectedMenu } from '../../models/selected-menu';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  isMenu: boolean = true;
  count:  string  = '0';

  @Input()
  menu: SelectedMenu = new SelectedMenu();

  @Input()
  report: Report = new Report();

  @Input()
  user: string = "";

  @Input()
  ismenuopen: boolean = true;

  constructor(private stateService: StateService,
              private modalService: ModalService) {
    this.menu = new SelectedMenu();
    this.stateService.reportListCount.subscribe(count => this.count = count.toString());
  }

  ngOnInit() {
    if (this.report.id > 0)
    {
      this.isMenu = false;
    }
  }

  rateReport() {
    this.modalService.open('app-report-rater', null, ()=>{}, this);
  }

  closeReport() {
    this.stateService.closeReportRequest.next(true);
  }

  getDataRefreshDate() {
    return this.stateService.getCustomer().refreshdate;
  }

  onRefreshDateClick() {
    this.modalService.open('app-dataset-viewer', null, ()=>{}, this);
  }

  getUserLastLoginDate() {
    return this.stateService.getCustomer().user.lastLogin;
  }

  lessThan(input: number, threshold: number) {
    return input < threshold;
  }
}

<div class="menu-container-side" *ngIf="location==='side'" [ngClass]="{'force-side-menu-open': forceShowMenu === true}">
  
  <div class="menu-header">
    <img class="menu-logo" *ngIf="opened || forceShowMenu">
    <div class="menu-button" [ngClass]="{'closed': !opened && !forceShowMenu}" (click)="onMenuToggleClick()">
      <mat-icon *ngIf="!opened && !forceShowMenu" class="menu-button-icon">menu</mat-icon>
      <mat-icon *ngIf="opened && !forceShowMenu" class="menu-button-icon">menu_open</mat-icon>
      <mat-icon *ngIf="forceShowMenu" class="menu-button-icon">close</mat-icon>
    </div>
  </div>

  <div class="user-info" *ngIf="user !== '' && opened">
    <table>
      <tr>
        <td>
          <div class="user-img-container">
            <div class="user-initial">{{userInitials}}</div>
          </div>
        </td>
        <td>
          <table>
            <tr>
              <td class="user-name">
                {{user}}
              </td>
            </tr>
            <tr>
              <td class="user-email">
                {{email}}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>

  <div class="user-info user-info-small" *ngIf="user !== '' && !opened">
    <div class="user-img-container user-img-container-small">
      <div class="user-initial user-initial-small">{{userInitials}}</div>
    </div>
  </div>

  <div class="menu-option" [ngClass]="{'selected': selectedMenu.item.id===myreportsMenu.id}" (click)="onMenuItemSelected(myreportsMenu)">
    <table width="256px">
      <tr>
        <td style="width:47px">
          <mat-icon class="menu-option-icon">{{myreportsMenu.icon}}</mat-icon>
        </td>
        <td class="menu-option-text" *ngIf="opened || forceShowMenu">
          {{myreportsMenu.title}}
        </td>
      </tr>
    </table>
  </div>

  <mat-accordion class="menu-accordion">
    
      <div class="expansion-panel-wrapper" *ngFor="let menu of menus">

        <div *ngIf="menu.items.length===0" class="menu-option" [ngClass]="{'selected': selectedMenu.item.id===menu.id}" (click)="onMenuItemSelected(menu)">
          <table width="256px">
            <tr>
              <td style="width:47px">
                <mat-icon class="menu-option-icon">{{menu.icon}}</mat-icon>
              </td>
              <td class="menu-option-text" *ngIf="opened || forceShowMenu">
                {{menu.title}}
              </td>
            </tr>
          </table>
        </div>


        <mat-expansion-panel
            *ngIf="menu.items.length>0" 
            class="mat-elevation-z0"
            hideToggle
            [expanded]="getExpansionPanelState(menu)" 
            (click)="onExpansionMenuClicked(menu)" 
            (opened)="onExpansionMenuOpened(menu)" 
            (closed)="onExpansionMenuClosed(menu)">
          <mat-expansion-panel-header class="menu-option" [ngClass]="{'selected': opened===false && selectedMenu.category.id===menu.id && !forceShowMenu}">
            <mat-panel-title class="menu-option" [ngClass]="{'selected': opened===false && selectedMenu.category.id===menu.id && !forceShowMenu}">
              <table width="256px">
                <tr>
                  <td style="width:47px">
                    <mat-icon class="menu-option-icon">{{menu.icon}}</mat-icon>
                  </td>
                  <td class="menu-option-text" *ngIf="opened || forceShowMenu">
                    {{menu.title}}
                  </td>
                  <td style="width:25px" *ngIf="opened || forceShowMenu">
                    <mat-icon id="{{getExpandIconId(menu)}}" class="menu-list-icon">add</mat-icon>
                  </td>
                </tr>
              </table>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list *ngIf="opened || forceShowMenu">
            <div class="menu-list-option-wrapper" *ngFor="let item of menu.items">
              <a mat-list-item class="menu-option menu-list-option" [ngClass]="{'selected': selectedMenu.item.id===item.id}" (click)="onMenuItemSelected(menu, item)">{{item.title}}</a>
            </div>
          </mat-nav-list>
        </mat-expansion-panel>
      </div>
      
  </mat-accordion>

  
  <div class="menu-option side-bottom" style="bottom:96px" [ngClass]="{'selected': selectedMenu.item.id===settingsMenu.id}" (click)="onMenuItemSelected(settingsMenu)">
    <table width="256px">
      <tr>
        <td style="width:47px">
          <mat-icon class="menu-option-icon">{{settingsMenu.icon}}</mat-icon>
        </td>
        <td class="menu-option-text" *ngIf="opened || forceShowMenu">
          {{settingsMenu.title}}
        </td>
      </tr>
    </table>
  </div>

  <div class="menu-option side-bottom" style="bottom:48px" [ngClass]="{'selected': selectedMenu.item.id===aboutMenu.id}" (click)="onMenuItemSelected(aboutMenu)">
    <table width="256px">
      <tr>
        <td style="width:47px">
          <mat-icon class="menu-option-icon">{{aboutMenu.icon}}</mat-icon>
        </td>
        <td class="menu-option-text" *ngIf="opened || forceShowMenu">
          {{aboutMenu.title}}
        </td>
      </tr>
    </table>
  </div>

  <div class="menu-option side-bottom" style="bottom:0" [ngClass]="{'selected': selectedMenu.item.id===logoutMenu.id}" (click)="logout()">
    <table width="256px">
      <tr>
        <td style="width:47px">
          <mat-icon class="menu-option-icon">{{logoutMenu.icon}}</mat-icon>
        </td>
        <td class="menu-option-text" *ngIf="opened || forceShowMenu">
          {{logoutMenu.title}}
        </td>
      </tr>
    </table>
  </div>
</div>

<div class="menu-container-bottom" *ngIf="location==='bottom'">

  <table style="margin:auto;width:50%">
    <tr>
      <td>
        <div class="menu-option" [ngClass]="{'selected': selectedMenu.category.id===0}" (click)="onMenuItemSelected(myreportsMenu)">
          <mat-icon class="menu-option-icon">{{myreportsMenu.icon}}</mat-icon>
        </div>
      </td>
  
      <td *ngFor="let menu of menus">
        <div class="menu-option" [ngClass]="{'selected': selectedMenu.category.id===menu.id}" (click)="onMenuItemSelected(menu)">
          <mat-icon class="menu-option-icon">{{menu.icon}}</mat-icon>
        </div>
      </td>

      <td>
        <div class="menu-option" (click)="showSideMenu(0)">
          <mat-icon class="menu-option-icon">menu</mat-icon>
        </div>
      </td>
    </tr>
  </table>
</div>
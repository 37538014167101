<h4 mat-dialog-title style="color:gray;overflow:hidden;margin-left:25px;margin-top:25px;">{{data.title || 'Error'}}</h4>
<div mat-dialog-content style="margin-left:25px;">
  {{data.message}}
</div>
<div mat-dialog-actions align="center" style="margin-top: 40px;">
  <button mat-button (click)="onCancelClick()" *ngIf="data.showCancel">Cancel</button>
  <button class="dialog-button" cdkFocusInitial (click)="onOkClick()">{{okButtonText}}</button>
  <button class="dialog-button" style="margin-left: 2px" (click)="onMoreInfoClick()" color="" *ngIf="hasMoreInfo(data.moreInformation)"><mat-icon style="vertical-align: middle">info</mat-icon></button>
</div>

<ng-template #moreInformationTemplate>
  <h4 mat-dialog-title style="color:gray;margin-left:25px;margin-top:25px;">More Information</h4>
  <div mat-dialog-content style="margin-left:25px;">
    {{data.moreInformation}}
  </div>

  <div mat-dialog-actions align="center" style="margin-top: 40px;">
    <button class="dialog-button" cdkFocusInitial (click)="onMoreInfoOkClick()">Close</button>
  </div>
</ng-template>
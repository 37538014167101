<div class="banner">
  <table class="title" *ngIf="isMenu===true">
    <tr>
      <td *ngIf="menu.category.id !== 0 && menu.category.title != ''">
        <div class="menu category">{{menu.category.title.toUpperCase()}}</div>
      </td>
      <td *ngIf="menu.category.id !== 0 && menu.category.title != ''">
        <div class="menu divider">></div>
      </td>
      <td>
        <div class="menu" [ngClass]="{'menu-bold': menu.category.id !== 0  && menu.category.title != ''}">{{menu.item.title.toUpperCase()}}</div>
      </td>
    </tr>
  </table>

  <!-- <div class="welcome > menu-open" *ngIf="isMenu===true">
    <span *ngIf="user!==''">Welcome {{user}}</span>
  </div> -->
    
  <table class="title" *ngIf="isMenu===false">
    <tr>
      <td>
        <div class="menu-bold">{{report.title.toUpperCase()}}</div>
      </td>
    </tr>
  </table>

  <ng-container *ngIf="isMenu===true && menu.item.id === 100">
    <table class="count">
      <tr>
        <td width=200px *ngIf="getUserLastLoginDate() !== ''">
          <div>Last login: {{getUserLastLoginDate() | shortDateTime}}</div>
        </td>
        <td>
          <div>Favorites: {{count}}</div>
        </td>
      </tr>
    </table>
  </ng-container>
  
  <ng-container *ngIf="isMenu===true && lessThan(menu.item.id, 100)">
    <table class="count">
      <tr>
        <td>
          <div>{{count}} <span *ngIf="count!=='1'">dashboards</span><span *ngIf="count==='1'">dashboard</span></div>
        </td>
      </tr>
    </table>
  </ng-container>

  <ng-container *ngIf="isMenu===false">
    <table class="last-view">
      <tr>
        <td width=200px>
          <div>Last viewed: {{report.lastViewed | shortDateTime}}</div>
        </td>
        <td>
          <div>Data refreshed: <span (click)="onRefreshDateClick()" class="link">{{getDataRefreshDate() | shortDateTime}}</span></div>
        </td>
      </tr>
    </table>
    
  </ng-container>

  <div class="button-container" *ngIf="isMenu===false">
    <table>
      <tr>
        <td>
          <button class="button rate" mat-button (click)="rateReport()">RATE</button>
        </td>
        <td>
          <button class="button close-report" mat-button (click)="closeReport()">CLOSE</button>
        </td>
      </tr>
    </table>
  </div>
  
</div>
<app-rater [report]="report"></app-rater>
<app-dataset-viewer></app-dataset-viewer>
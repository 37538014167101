<aw-modal id="app-dataset-viewer">
  <div class="dataset-container">
    <div class="dataset-viewer-header">
      <table width="100%">
        <tr>
          <td>
            <div class="dataset-viewer-title">DATASET INFORMATION</div>
          </td>
          <td>
            <mat-icon class="dataset-viewer-close" (click)="cancel()">close</mat-icon>
          </td>
        </tr>
      </table>
    </div>

    <div class="dataset-viewer-model-date">
      Data model was refreshed on {{getDataRefreshDate() | shortDateTime}}
    </div>

    <div *ngIf="displayInfo" class="dataset-viewer-info info-expanded">
      <table>
        <tr>
          <td width="50px">
            <mat-icon>info</mat-icon>
          </td>
          <td>
            <div class>
              All dashboard data originates from the data model. Individual datasets are populated by incoming API requests and processed by our background ETL logic at regular intervals. It's possible for a dataset to contain a later date than the model refresh date. If this occurs, the newer dataset data will be included within the data model during the next data model refresh
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div (click)="onDismissInfoClick()" class="dataset-viewer-info-dismiss">Dismiss</div>
          </td>
        </tr>
      </table>
    </div>

    <div *ngIf="!displayInfo" class="dataset-viewer-info">
      <table>
        <tr>
          <td width="50px">
            <mat-icon>info</mat-icon>
          </td>
          <td>
            <div (click)="onRefreshInfoClick()" class="clickable-info-text">
              Dataset refresh information
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="dataset-viewer-table mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 dataset-table">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
          <th (click)="sortData(column)" class="column-header" mat-header-cell *matHeaderCellDef>
            {{column.header}} <mat-icon>{{getColumnSortIcon(column)}}</mat-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="column.dataType === 'date'"><table><tr><td width="135px">{{(column.cell(row)) | shortDateTime}}</td><td><div title="{{getStatusTitle(row)}}" class="{{getStatusIndicatorClassName(row)}}"></div></td></tr></table></div>
            <div *ngIf="column.dataType !== 'date'">{{column.cell(row)}}</div>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</aw-modal>
<aw-modal id="app-report-save-filter">
  <div class="save-filter-container">
    <div class="title">SAVE NEW FILTER</div>

    <input #filtername class="form-control filter-text" placeholder="Filter name" (keyup.enter)="onEnter($event)"/>

    <div class="filter-button-container">
      <table>
        <tr>
          <td>
            <button mat-button class="filter-button" (click)="cancel()">CANCEL</button>
          </td>
          <td>
            <button mat-button class="filter-button" (click)="submit()">SUBMIT</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</aw-modal>
<aw-modal id="app-report-rate-viewer">
  <div class="rate-viewer-container">
    <div class="rate-viewer-header">
      <table width="100%">
        <tr>
          <td>
            <div class="rate-viewer-title">RATINGS & REVIEWS</div>
          </td>
          <td>
            <mat-icon class="rate-viewer-close" (click)="cancel()">close</mat-icon>
          </td>
        </tr>
      </table>
    </div>
    
    <table height="150px" width="100%">
      <tr>
        <td width="50%">
          <div class="rate-viewer-score">{{rating.toFixed(1)}}</div>
    
          <div class="rate-widget-container">
            <app-rate-widget [rating]=rating size='medium'></app-rate-widget>
          </div>
        </td>
        <td  width="50%">
          <app-rate-distribution-widget [reviews]=reviewsClone (distributionItemClick)="onDistributionWidgetClick($event)"></app-rate-distribution-widget>
        </td>
      </tr>
    </table>

    

    <div class="rate-viewer-count">Total Reviews: {{reviewCount}}</div>

    <div class="rate-viewer-comments">
      <app-rating-item *ngFor="let review of reviews" [review]=review (removeReview)="onRemoveReview($event)"></app-rating-item>
    </div>
  </div>  
</aw-modal>
<div class="ngx-dropdown-container" tabindex="0">
    <button type="button" tabindex="-1" class="ngx-dropdown-button" [ngClass]="{ 'ngx-disabled': disabled }"
        [disabled]="disabled" (click)="toggleSelectDropdown()">
        <span class="display-text">{{ selectedDisplayText }} </span>
        <span class="nsdicon-angle-down"></span>
    </button>
    <div class="ngx-dropdown-list-container" *ngIf="toggleDropdown" [style.maxHeight]="config.height">
        <div class="search-container" *ngIf="config.search">
            <input #searchtext (change)="changeSearchText($event)" [style.direction]="config.inputDirection" name="search-text"
                (input)="searchTextChanged()" [(ngModel)]="searchText" tabindex="-1" autocomplete="off" />
            <label [ngClass]="{ active: searchText }">
                <span class="nsdicon-search"></span>
                {{ config.searchPlaceholder }}</label>
        </div>
        <ul class="selected-items">
            <li tabindex="-1" *ngFor="let selected of selectedItems; let i = index" (click)="deselectItem(selected, i)">
                <span class="nsdicon-close">x</span>
                <span>
                    {{
                    config.listDisplayFn
                    ? config.listDisplayFn(selected)
                    : selected[config.displayKey] || selected
                    }}
                </span>
            </li>
        </ul>
        <hr *ngIf="selectedItems.length > 0 && availableItems.length > 0" />
        <ul class="available-items">
            <li #availableOption *ngFor="
            let item of availableItems
              | filterBy: searchText:config.searchOnKey
              | limitTo: config.limitTo;
            let i = index
          " tabindex="-1" [ngClass]="{
            active: focusedItemIndex == i && !item.disabled,
            disabled: item.disabled
          }" (click)="selectItem(item, i)">
                {{
                config.listDisplayFn
                ? config.listDisplayFn(item)
                : item[config.displayKey] || item
                }}
            </li>
            <li *ngIf="showNotFound">{{ config.noResultsFound }}</li>
        </ul>
    </div>
</div>
import { EventType } from '@azure/msal-browser';
import { StateService } from './../../../../services/state.service';
import { NotificationService } from './../../../../services/notification.service';
import { ModalService, OpenModalRequest } from './../../../../services/modal.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-report-filter-new',
  templateUrl: './report-filter-new.component.html',
  styleUrls: ['./report-filter-new.component.scss']
})
export class ReportFilterNewComponent implements OnInit {

  @ViewChild('filtername', {static: true}) filterName: ElementRef;

  constructor(private modalService: ModalService,
              private notificationService: NotificationService,
              private stateService: StateService) {

                this.modalService.onModalOpen.subscribe((openModalRequest: OpenModalRequest)=>{
                  if (openModalRequest.id === 'app-report-save-filter')
                  {
                    setTimeout(() => {
                      this.filterName.nativeElement.value = '';
                      this.filterName.nativeElement.focus();
                    }, 10);
                  }
                });

              }

  ngOnInit() {
  
  }

  closeModal(name) {
    this.modalService.close('app-report-save-filter', {
      "name": name
    });
  }

  cancel() {
    this.closeModal('');
  }

  onEnter(event) {
    event.stopPropagation();
    this.submit();
  }

  submit() {

    const name: String = this.filterName.nativeElement.value;

    if (this.stateService.isEmpty(name))
    {
      this.notificationService.showAlert("Error","Please provide a name for the new filter");
      return;
    }

    // Name must be 17 characters or less
    if (name.length > 17)
    {
      this.notificationService.showAlert("Error","Filter name must be 17 characters or less");
      return;
    }

    // Does the filter name already exist?
    // @ts-ignore
    if (this.stateService.savedReportFilters.findIndex(f => f.name.toUpperCase() === name.toUpperCase()) > -1)
    {
      this.notificationService.showAlert("Error", "Filter with name '" + name + "' already exists.", false, ()=>{
        setTimeout(() => {
          this.filterName.nativeElement.focus();
        }, 10);
      });
      return;
    }

    this.closeModal(name);
  }
}

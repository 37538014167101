import { Component, Input, OnInit } from '@angular/core';

interface Star {
  id: number;
  active: boolean;
}

@Component({
  selector: 'app-rate-widget',
  templateUrl: './rate-widget.component.html',
  styleUrls: ['./rate-widget.component.scss']
})
export class RateWidgetComponent implements OnInit {

  @Input()
  rating: number = 0;

  @Input()
  size: string = "large";

  stars: Star[] = [
    {
      id: 1,
      active: false
    },
    {
      id: 2,
      active: false
    },
    {
      id: 3,
      active: false
    },
    {
      id: 4,
      active: false
    },
    {
      id: 5,
      active: false
    }
  ]

  constructor() { }

  ngOnInit() {
  }

  getRatingIcon(rating: Star) {
        
    if (rating.id - this.rating > 0 && rating.id - this.rating < 1)
    {
      return "star_half";
    }

    return "star";
  }

  getRatingClass(rating: Star) {

    const defaultClass = "rating-star noselect";

    if (rating.id <= this.rating)
    {
      return defaultClass;
    }
   
    if (rating.id - this.rating > 0 && rating.id - this.rating < 1)
    {
      return defaultClass;
    }

    return defaultClass + " off";
  }

  getRatingWidth() {

    if (this.size == "small")
    {
      return "105px";
    }

    if (this.size == "medium")
    {
      return "150px";
    }

    return "200px";
  }
}

<div class="review-item-container">
  <table>
    <tr>
      <td>
        <div class="review-item-source">{{review.user}} @ {{review.customer}}</div>
      </td>
      <td>
        <mat-icon *ngIf="review.allowDelete" class="review-item-delete" (click)="onRemoveCommentClick()">delete</mat-icon>
      </td>
    </tr>
  </table>
  
  <table width="100%">
    <tr>
      <td width="100px">
        <app-rate-widget [rating]=review.rating size='small'></app-rate-widget>
      </td>
      <td width="50px">
        <div class="rating-current">
          {{getRatingText()}}
        </div>
      </td>
      <td>
        <div class="review-item-date">{{getReviewDate()}}</div>
      </td>
    </tr>
  </table>

  <div class="review-item-comment">
    {{review.comment}}
  </div>
</div>
<hr>
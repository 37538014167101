import { User, Customer } from './../models/customer';
import { KeyValue } from '@angular/common';
import { SelectedMenu } from './../models/selected-menu';
import { Filter, Rating, Report, SavedFilter, ReportDetail } from './../models/report';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
  })
  export class StateService {
  
    public constructor(private router: Router) {}

    public readonly MY_REPORTS: number = 100;

    public isAuthenticated: boolean = false;
    public isMenuOpen = new BehaviorSubject<boolean>(true);
    public selectedMenu = new BehaviorSubject<SelectedMenu>(undefined);
    public reportListCount = new BehaviorSubject<number>(0);
    public reportDetail = new BehaviorSubject<ReportDetail>(undefined);
    public savedReportFilters: SavedFilter[] = [];
    public reportLoaded = new BehaviorSubject<Report>(undefined);
    public closeReportRequest = new BehaviorSubject<boolean>(false);
    public reportReviewRemoved = new BehaviorSubject<boolean>(false);
    
    public isCustomerLoaded = new BehaviorSubject<boolean>(false);
    private customer: Customer = undefined;
    
    public fullScreen: boolean = false;

    routerNavigate(path: any[], fullScreen: boolean)
    {
      this.fullScreen = fullScreen;
      
      if (fullScreen)
      {
        this.router.navigate([{outlets: {fullscreen: path}}], {skipLocationChange: true});
        return;
      }

      this.router.navigate(path, {skipLocationChange: true});
    }

    clearReportState() {
      this.savedReportFilters = [];
    }

    setCustomer(customer: Customer) {
      this.customer = customer;

      if (!this.isEmpty(this.customer.user))
      {
        this.isCustomerLoaded.next(true);
      }
    }

    getCustomer() {
      return this.customer;
    }

    getReport(id: number) {
      for (let i=0; i < this.customer.reports.length; i++)
      {
        if (this.customer.reports[i].id === id)
        {
          return this.customer.reports[i];
        }
      }

      return new Report();
    }

    public titleCase(str) {
      return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
    }

    public isEmpty(value: any)
    {
      if (value === undefined || value === null || value === '')
      {
        return true;
      }
  
      return false;
    }

  }
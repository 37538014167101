import { Report, SavedFilter } from './report';
export class Customer {
    public id: string;
    public name: string;
    public datasource: string;
    public user: User;
    public reports: Report[] = [];
    public refreshdate: Date;
    public datasets: Dataset[] = [];

    public constructor(init?: Partial<Customer>) {
        Object.assign(this, init);
    }
}

export class User {
    public id: string;
    public firstName: string;
    public lastName: string;
    public lastLogin: Date;
   
    public constructor(init?: Partial<User>) {
        Object.assign(this, init);
    }
}

export class Dataset {
    public name: string;
    public frequency: string;
    public date: string;
}
import { CustomerService } from './services/customer.service';
import { Report } from 'src/app/models/report';
import { StateService } from 'src/app/services/state.service';
import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { SelectedMenu } from './models/selected-menu';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { loginRequest } from './auth-config';
import { Customer } from './models/customer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'AssetAnalytics';
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  @ViewChild('sidenav') sidenav: MatDrawer;

  menuWasClosed: boolean;
  isMenuOpened: boolean = true;
  forceSideMenuOpen: boolean = false;
  expandCategory: number;

  selectedMenu: SelectedMenu = new SelectedMenu();

  reportCount: string = '0';
  //fullScreen: boolean = false;
  user: string = '';
  
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              public stateService: StateService,
              private router: Router,
              private route: ActivatedRoute,
              private customerService: CustomerService) {

                this.stateService.isAuthenticated = false;
                this.login();
              }

  ngOnInit() {
   
  }

  onMenuChange(menu: SelectedMenu) {
    
    // Logout
    if (menu.item.id === 102)
    {
      this.menuWasClosed = false;
      this.stateService.isMenuOpen.next(true);
      this.stateService.isAuthenticated = false;
      this.openMenu();
      
      setTimeout(()=>{
        this.stateService.selectedMenu.next(undefined);
      }, 100);
    }

    // About
    if (menu.item.id === 101)
    {
      this.stateService.routerNavigate(["about"], false);
    }

    // Settings
    if (menu.item.id === 103)
    {
      this.stateService.routerNavigate(["settings"], false);
    }

    if (menu.item.id <= 100)
    {
      this.stateService.routerNavigate(["reports"], false);
    }

    this.stateService.selectedMenu.next(menu);

    this.getReportCount();
  }

  getReportCount() {
    // Report Count
    if (this.stateService.getCustomer() === undefined)
    {
      return 0;
    }

    this.reportCount = this.stateService.getCustomer().reports.reduce((r: any, v: Report) => {
      if (v.menus.includes(this.stateService.selectedMenu.value.item.id))
      {
        r++;
      }
    
      return r;
    }, "0").toString();
  }

  onForceSideMenuOpen(categoryId) {

    if (categoryId > 0)
    {
      return;
    }

    this.forceSideMenuOpen = true;
    this.expandCategory = categoryId;
    this.sidenav.mode = "over";
  
    if (!this.isMenuOpened)
    {
      this.menuWasClosed = true;
      this.openMenu();
    }
  }

  onForceMenuClose() {
    this.forceSideMenuOpen = false;
    this.expandCategory = undefined;
        
    if (this.menuWasClosed)
    {
      this.menuWasClosed = false;
      this.closeMenu();
    }

    this.sidenav.mode = "side";
  }

  onMenuToggle(event) {
    
    // State service (isMenuOpen) is only used on the 
    // report list item component at the moment. Therefore,
    // we only want to set it via toggle vs. the open/close
    // menu functions (for now)
    //console.log(event);

    if (event.opened)
    {
      this.closeMenu();
      this.stateService.isMenuOpen.next(false);
      return;
    }
    
    this.openMenu()
    this.stateService.isMenuOpen.next(true);
  }

  openMenu() {
    this.isMenuOpened = true;
    this.sidenav.open();
  }

  closeMenu() {
    this.isMenuOpened = false;
    this.sidenav.close();
  }

  login() {

    // Account selection logic is app dependent. Adjust as needed for different use cases.
    // Set active acccount on page load
    const accounts = this.authService.instance.getAllAccounts();
    if (accounts.length > 0) {
      this.authService.instance.setActiveAccount(accounts[0]);
      this.user = accounts[0].name;
      this.getCustomer();      
      return;
    }
    
    this.authService.instance.addEventCallback((event) => {
      // set active account after redirect
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        this.authService.instance.setActiveAccount(account);
        this.user = account.name;
        this.getCustomer();        
      }
    });
    
    console.log('get active account', this.authService.instance.getActiveAccount());
    
    // handle auth redired/do all initial setup for msal
    this.authService.instance.handleRedirectPromise().then(authResult=>{
      // Check if user signed in
      const account = this.authService.instance.getActiveAccount();
      
      if(!account){
        // redirect anonymous user to login page 
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        localStorage.setItem('loginRequest', 'true');
      }
    }).catch(err=>{
      // TODO: Handle errors
      console.error(err);
    });
  }

  getCustomer() {
    this.customerService.getCustomer().then(
      (customer: Customer) => {
        this.stateService.setCustomer(customer);
        this.stateService.isAuthenticated = true;

        // Local storage is set when the user is redirected to the Microsoft login page. Therefore,
        // if local storage variable 'loginRequest' is present then the user just logged-in and
        // we'll set a last login date. 
        const loginRequest = localStorage.getItem('loginRequest');

        if (loginRequest === 'true')
        {
          localStorage.removeItem('loginRequest');
          this.customerService.setLastLoginDate();
        }
        
      }
    );
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

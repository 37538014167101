import { CustomerService } from 'src/app/services/customer.service';
import { StateService } from 'src/app/services/state.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Report } from 'src/app/models/report';

@Component({
  selector: 'app-report-item',
  templateUrl: './report-item.component.html',
  styleUrls: ['./report-item.component.scss']
})
export class ReportItemComponent implements OnInit {

  @Input()
  size: string = 'small';

  @Input()
  report: Report = new Report();

  @Output()
  favorite: EventEmitter<boolean> = new EventEmitter();

  constructor(private stateService: StateService,
              private customerService: CustomerService) {
    this.stateService.isMenuOpen.subscribe(result => {
      if (result===true)
      {
        this.size = 'small';
        return;
      }

      this.size = 'large';
    })
  }

  ngOnInit() {
  }

  showReport(report: Report) {
    this.stateService.routerNavigate(["reports", report.id], true);
  }

  isFavorite() {
    return this.report.menus.includes(this.stateService.MY_REPORTS);
  }

  setFavorite(value: boolean, event) {

    event.stopPropagation();

    if (value===false) {
      const favIndex = this.report.menus.findIndex(n => n === this.stateService.MY_REPORTS);

      if (favIndex > -1)
      {
        this.report.menus.splice(favIndex, 1);
      }

      this.customerService.removeReportToFavorites(this.report);
      this.favorite.emit(false);
      return;
    }

    this.report.menus.push(this.stateService.MY_REPORTS);
    this.customerService.addReportToFavorites(this.report);
    this.favorite.emit(true);
  }

  getThumbnail() {

    if (this.stateService.isEmpty(this.report.thumbnail))
    {
      return '../../../assets/images/sample-report.png';
    }

    return '../../../assets/thumbnails/' + this.report.thumbnail;
  }
}

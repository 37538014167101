import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Report, Rating } from './../models/report';

@Injectable()
export class ReportService {

constructor(private api: ApiService) { }

getReportRatings(report: Report) {
  return this.api.get(report.ratingUrl).then((data)=>{
    return new Promise((resolve, reject) => {
      try
      {
        resolve(data['ProcessOutcome']['Ratings']);
      }
      catch (e)
      {
        reject(e);
      }
    })
  });
}

addReportRating(report: Report, rating: Rating) {
  this.api.post(report.ratingUrl, rating);
}

removeReportRating(rating: Rating) {
  return this.api.delete(rating.ratingUrl, rating);
}

}

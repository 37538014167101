<table>
  <tr>
    <td>
      <app-rate-widget [rating]=report.rating size='small'></app-rate-widget>
    </td>
    <td>
      <div class="rating-current">
        {{getRatingText()}}
      </div>
    </td>
    <td>
      <div *ngIf="report.ratingCount>0" class="view-rating" (click)="onViewRatingClick($event)">View rating</div>
    </td>
  </tr>
</table>
import { StateService } from 'src/app/services/state.service';
import { SelectedMenu } from '../../models/selected-menu';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Menu, MenuItem } from 'src/app/models/menu';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {

  opened: boolean = true;
  menuWasClosedButForcedOpen: boolean = false;

  menuCategorySelected: number = 0;

  myreportsMenu: Menu = new Menu({
    id: this.stateService.MY_REPORTS,
    icon: 'person',
    title: 'My Favorites',
    items: []
  });

  user: string = '';
  email: string = '';
  userInitials: string = '';

  @Input()
  location: string = 'side';

  @Input()
  expandCategory: number;

  @Input()
  selectedMenu: SelectedMenu = new SelectedMenu();

  @Output()
  selectedMenuChange = new EventEmitter<SelectedMenu>();

  @Input()
  forceShowMenu: boolean = false;

  @Output()
  forceShowMenuChange = new EventEmitter<number>();

  @Output()
  forceCloseMenu = new EventEmitter<boolean>();

  @Output()
  toggle: EventEmitter<{
                        opened: boolean
                      }> = new EventEmitter();

  
                      
  
  
  settingsMenu: Menu = new Menu({
    id: 103,
    icon: 'settings',
    title: 'Settings',
    items: []
  });
  
  aboutMenu: Menu = new Menu({
    id: 101,
    icon: 'help',
    title: 'About',
    items: []
  });

  logoutMenu: Menu = new Menu({
    id: 102,
    icon: 'exit_to_app',
    title: 'Logout',
    items: []
  });

  menus: Menu[] = [
    new Menu({
      id: 1,
      icon: 'emoji_transportation',
      title: 'Assets',
      items: []
    }),
    new Menu({
      id: 2,
      icon: 'charging_station',
      title: 'Energy',
      items: []
    }),
    new Menu({
      id: 3,
      icon: 'groups',
      title: 'Labor',
      items: []
    }),
    new Menu({
      id: 4,
      icon: 'build_circle',
      title: 'Maintenance Operation',
      items: []
    }),
    new Menu({
      id: 5,
      icon: 'departure_board',
      title: 'Preventive Maintenance',
      items: []
    }),
    new Menu({
      id: 34,
      icon: 'speed',
      title: 'Telematics',
      items: []
    }),
    new Menu({
      id: 35,
      icon: 'psychology_alt',
      title: 'Predictive',
      items: []
    }),
    new Menu({
      id: 33,
      icon: 'query_stats',
      title: 'Utilization',
      items: []
    }),
    new Menu({
      id: 6,
      icon: 'table_rows',
      title: 'Table Query',
      items: []
    })
  ];
  
  constructor(private stateService: StateService,
              private authService: MsalService) {
    this.selectedMenu = new SelectedMenu();
  }

  ngOnInit() {
    this.opened = true;

    this.stateService.isCustomerLoaded.subscribe(loaded => {
      if (loaded)
      {
        this.email = this.stateService.getCustomer().user.id;
        this.user = this.stateService.getCustomer().user.firstName + ' ' + this.stateService.getCustomer().user.lastName;
        this.userInitials = this.stateService.getCustomer().user.firstName.substring(0, 1) + this.stateService.getCustomer().user.lastName.substring(0, 1);

        const userMenus = [];

        this.stateService.getCustomer().reports.forEach(r => {
          r.menus.forEach(m => {
            if (!userMenus.includes(m)) {
              userMenus.push(m);
            }
          });
        });

        this.menus = this.menus.filter(function (element) {
          return userMenus.indexOf(element.id) >= 0;
        });
      }
    });
    
    if (this.stateService.selectedMenu.value !== undefined && this.stateService.selectedMenu.value.item.id !== 102)
    {
      this.selectedMenuChange.emit(this.stateService.selectedMenu.value);
      this.forceExpansionMenuOpen(new Menu({id: this.stateService.selectedMenu.value.category.id}));
      return;
    }

    this.selectedMenuChange.emit(new SelectedMenu({
      category: {
        id: 0,
        title: ''
      },
      item: {
        id: this.myreportsMenu.id,
        title: this.myreportsMenu.title
      }
    }));
  }

  onMenuToggleClick(discardNewState: boolean = false) {

    if (discardNewState)
    {
      return;
    }

    if (this.forceShowMenu)
    {
      this.forceMenuClosed();
      return;
    }
   
    this.opened = this.opened === true ? false : true;
    this.toggle.emit({
                      opened: !this.opened
                    });
  }

  onMenuItemSelected(menu: Menu, item: MenuItem = undefined) {
    
    this.menuCategorySelected = item === undefined ? 0 : menu.id;
    
    this.selectedMenuChange.emit(new SelectedMenu({
      category: {
        id:  menu.id,
        title:  item === undefined ? '' : item.title
      },
      item: {
        id: item === undefined ? menu.id : item.id,
        title: item === undefined ? menu.title : item.title
      }
    }));

    if (this.forceShowMenu)
    {
      this.forceMenuClosed();
    }
  }

  onExpansionMenuOpened(menu: Menu) {
    setTimeout(()=>{
      const e = this.getExpandIconElement(menu);

      if (e == undefined)
      {
        return;
      }
      e.innerText = "remove";
    }, 100);
  }

  onExpansionMenuClosed(menu: Menu) {
    setTimeout(()=>{
      const e = this.getExpandIconElement(menu);

      if (e == undefined)
      {
        return;
      }
      e.innerText = "add";
    }, 100);
  }

  onExpansionMenuClicked(menu: Menu) {
    this.forceMenuOpen();
    this.forceExpansionMenuOpen(menu);
  }

  getExpansionPanelState(menu: Menu) {
    
    if (this.forceShowMenu && this.expandCategory === menu.id)
    {
      return true;
    }

    if (!this.opened)
    {
      return false;
    }

    if (this.selectedMenu === undefined || this.selectedMenu.category.id === 0)
    {
      return false;
    }
    
    return menu.id === this.menuCategorySelected;
  }

  forceMenuOpen() {
    if (this.opened === false && this.forceShowMenu === false)
    {
      this.onMenuToggleClick(true);
    }
  }

  forceMenuClosed() {
    this.forceCloseMenu.emit(true);

    if(this.menuWasClosedButForcedOpen)
    {
      this.opened = false;
    }
  }

  forceExpansionMenuOpen(menu: Menu) {
    this.menuCategorySelected = menu.id;
  }

  showSideMenu(categoryId: number) {
    this.forceShowMenuChange.emit(categoryId);
  }

  getExpandIconElement(menu: Menu) {
    return document.getElementById(this.getExpandIconId(menu));
  }

  getExpandIconId(menu: Menu)
  {
    return "expand-icon-" + menu.id;
  }

  logout() {
    this.authService.logout();
  }
}

export class SelectedMenu {
    category: SelectedMenuItem = new SelectedMenuItem();
    item: SelectedMenuItem = new SelectedMenuItem();

    public getUrlArray() {
        if (this.category.id===0)
        {
            return ['reports', 'category', this.item.id];
        }
        else
        {
            return ['home', 'reports', 'category', this.category.id];
        }
    }

    public constructor(init?: Partial<SelectedMenu>) {
        Object.assign(this, init);
    }
}

export class SelectedMenuItem {
    public id: number = 0;
    public title: string = '';

    public constructor(init?: Partial<SelectedMenuItem>) {
        Object.assign(this, init);
    }
}
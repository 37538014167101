import { NotificationComponent } from './../components/notification/notification.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

@Injectable()
export class NotificationService {

  constructor(public dialog: MatDialog) { }

  showAlert(title: string, message: string, showCancel?: boolean, fn?: any, moreInformation?: string) {
    
    //this.stateService.loadingIndicator.next(false);

    const dialogRef = this.dialog.open(NotificationComponent, {
      width: '270px',
      height: '300px',
      data: { message: message, title: title, showCancel: showCancel, moreInformation: moreInformation}
    });

    dialogRef.afterClosed().subscribe(result => {
      
      if (fn == undefined)
      {
        return;
      }
      
      fn(result);
    });
  }
}
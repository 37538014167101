<div class="container">
  <mat-tab-group class="tab-group">
    <mat-tab label="Code Translation">
      <mat-form-field appearance="fill" class="cm-code-dropdown">
        <mat-label>Code</mat-label>
        <mat-select>
          <mat-option *ngFor="let codeMapping of codeMappings" [value]="codeMapping.type" (click)="onCodeTypeClick(codeMapping)">
            {{codeMapping.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="grid-container">

        <div *ngIf="noData">No data available</div>
      
        <table *ngIf="!noData" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
        
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element" > {{stateService.titleCase(element.description)}} </td>
          </ng-container>
        
          <ng-container matColumnDef="mapping">
            <th mat-header-cell *matHeaderCellDef> Translation </th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field appearance="fill" class="cm-code-dropdown">
                <mat-label></mat-label>
                <mat-select [value]="element.translation" 
                            (selectionChange)="onTranslationSelected($event, element, $event.value)">
                  <mat-option *ngFor="let option of mappingOptions" [value]="option.code">
                    {{stateService.titleCase(option.description)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator *ngIf="!noData" [pageSizeOptions]="[5, 15, 25, 50, 100]"
                  showFirstLastButtons 
                  aria-label="Select page of code mappings"
                  class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
import { map } from 'rxjs/operators';
import { StateService } from './state.service';
import { Subject, Observable } from 'rxjs';
import { ComponentFactoryResolver, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalService {
    
    public onModalOpen = new Subject<OpenModalRequest>();

    private modals: any[] = [];

    private closeFn: Function = undefined;
    private scope: any = undefined;

    public openModalCount: number = 0;

    add(modal: any) {
        // add modal to array of active modals
        if (this.modals.includes(modal))
        {
            console.log(`Modal '${modal.id}' already included in modal array`);
            return;
        };

        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string, data: any, closeFn: Function, scope: any) {
        // open modal specified by id
        this.openModalCount++;
        const modal = this.modals.find(x => x.id === id);
        modal.open();
        
        setTimeout(()=>{
            this.onModalOpen.next(new OpenModalRequest({
                id: id,
                data: data
            }));
        }, 10);

        this.closeFn = closeFn;
        this.scope = scope;
    }

    close(id: string, response: object = {}) {
        // close modal specified by id
        this.openModalCount--;
        const modal = this.modals.find(x => x.id === id);
        modal.close();

        if (this.closeFn === undefined)
        {
            return;
        }

        this.closeFn.call(this.scope, response);
    }
}

export class OpenModalRequest {
    id: string;
    data: any;

    public constructor(init?: Partial<OpenModalRequest>) {
        Object.assign(this, init);
    }
}
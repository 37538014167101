import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Rating } from 'src/app/models/report';

interface RatingIndicator {
  id: number;
  active: boolean;
}

@Component({
  selector: 'app-rating-item',
  templateUrl: './rating-item.component.html',
  styleUrls: ['./rating-item.component.scss']
})
export class RatingItemComponent implements OnInit {

  @Input()
  review: Rating

  @Output()
  removeReview: EventEmitter<Rating> = new EventEmitter();

  ratings: RatingIndicator[] = [
    {
      id: 1,
      active: false
    },
    {
      id: 2,
      active: false
    },
    {
      id: 3,
      active: false
    },
    {
      id: 4,
      active: false
    },
    {
      id: 5,
      active: false
    }
  ]


  constructor() { }

  ngOnInit() {
  }

  getRatingIcon(rating: RatingIndicator) {
        
    if (rating.id <= this.review.rating)
    {
      return "star";
    }
   
    if (rating.id - this.review.rating > 0 && rating.id - this.review.rating < 1)
    {
      return "star_half";
    }

    return "star_outline";
  }

  getRatingText() {
    return this.review.rating.toFixed(1);
  }

  getReviewDate() {

    const days = (new Date().getUTCDate() - new Date(this.review.date).getDate());

    if (days == 0)
    {
      return "Today";  
    }

    if (days == 1)
    {
      return "1 day ago";
    }

    return `${days} days ago`;
  }

  onRemoveCommentClick() {
    if(confirm("Continue removing comment?")) {
      this.removeReview.emit(this.review);
    }
  }
}

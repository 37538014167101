<table width="{{getRatingWidth()}}">
  <tr>
    <td nowrap width="40px" *ngFor="let star of stars" >
      <mat-icon 
        class="{{getRatingClass(star)}}" [ngClass]="{'large': size == 'large', 'medium': size == 'medium', 'small': size == 'small'}">
        {{getRatingIcon(star)}}
    </mat-icon>
    </td>
  </tr>
</table>

import { Customer } from './../../models/customer';
import { ApiService } from './../../services/api.service';
import { Report, ReportDetail, SavedFilter } from './../../models/report';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from 'src/app/services/state.service';
import { Component, OnInit } from '@angular/core';
import * as pbi from 'powerbi-client';
import { CustomerService } from 'src/app/services/customer.service';
import { Token } from 'src/app/models/token';

@Component({
  selector: 'app-report-container',
  templateUrl: './report-container.component.html',
  styleUrls: ['./report-container.component.scss']
})
export class ReportContainerComponent implements OnInit {

  report: Report;
  selectedTimeframe: string = '7';
  timeframes: object = [
    {
      label: 'LAST 7 DAYS',
      value: '7'
    },
    {
      label: 'LAST 14 DAYS',
      value: '14'
    },
    {
      label: 'LAST 30 DAYS',
      value: '30'
    },
    {
      label: 'CUSTOM RANGE',
      value: 'C'
    }
  ];

  embeddedReport: any = undefined;

  showFilter: boolean = false;
  showFilterButton: boolean = false;
  filterCount: number = 0;
  
  constructor(private stateService: StateService,
              private route: ActivatedRoute,
              private router: Router,
              private customerService: CustomerService) {
                
                if (this.stateService.closeReportRequest.observers.length === 0)
                {
                  this.stateService.closeReportRequest.subscribe(close => {
                    if (close)
                    {
                      this.onCloseClick();
                    }
                  });
                }
              }

              

  ngOnInit() {
    console.log('Report container Init');
    
    this.stateService.clearReportState();

    this.report = this.stateService.getReport(Number(this.route.snapshot.paramMap.get('id')));

    if (this.report.filters.length === 0)
    {
      this.showFilterButton = false;
    }

    this.showReport();
  }

  ngOnDestroy() {
    console.log('Report container Destroy');
  }

  onCloseClick() {
    
    // Retrieve customer information to refresh 
    // local report last view information
    this.customerService.getCustomer().then(
      (customer: Customer) => {
        this.stateService.setCustomer(customer);
        this.stateService.routerNavigate(["reports"], false);
      }
    );
  }

  onTimeframeClick(value) {
    this.selectedTimeframe = value;
    //this.embeddedReport.refresh();
  }

  onFilterClick() {
    console.log("Filter Clicked!");
    this.showFilter = true;
    this.showFilterButton = false;
  }

  onFilterClose() {
    this.showFilter = false;
    this.showFilterButton = true;
  }

  onFilterChange(data) {

    if (this.stateService.isEmpty(this.embeddedReport))
    {
      return;
    }

    this.embeddedReport.setFilters(data.filters);
    this.filterCount = data.count;
  }

  showReport() {
    // Report's Secured Token
    
    this.customerService.getReportDetail(this.report).then(
      (reportDetail : ReportDetail) => {
        const accessToken = reportDetail.accessToken;
        
        this.stateService.reportDetail.next(reportDetail);
                
        // Report ID
        const embedReportId = this.report.powerBi.id;
    
        const models = pbi.models;
      
        // We give All permissions to demonstrate switching between View and Edit mode and saving report.
        const permissions = models.Permissions.Read;
    
        // Configuration used to describe the what and how to embed.
        // This object is used when calling powerbi.embed.
        // This also includes settings and options such as filters.
        // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.
        const config = {
          type: 'report',
          tokenType: models.TokenType.Embed,
          accessToken: accessToken,
          embedUrl: this.report.powerBi.url,
          id: embedReportId,
          datasetBinding: {
            datasetId: this.stateService.getCustomer().datasource
          },
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false
          }
        };
    
        // Grab the reference to the div HTML element that will host the report.
        const reportContainer = <HTMLElement>document.getElementById('reportContainer');
    
        // Embed the report and display it within the div container.
        const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        const embed = powerbi.embed(reportContainer, config);
    
        const report = powerbi.get(reportContainer);

        this.embeddedReport = report;

        embed.iframe.style.border = 'none';

        // Report.off removes a given event handler if it exists.
        embed.off('loaded');
    
        // Report.on will add an event handler which prints to Log window.
        const me = this;

        embed.on('loaded', function() {
            console.log('Loaded');

            // @ts-ignore
            me.embeddedReport.getFilters().then(filters => {
              console.log('********** Report Filters **********');
              console.log(filters);
            });

            if (me.report.filters.length > 0)
            {
              me.showFilterButton = true;
            }

            me.stateService.reportLoaded.next(me.report);
            

            // Save the fact that we're about to view this report as the "last view" information
            me.customerService.setReportLastViewDate(me.report);
        });
       }
    );
  }
}
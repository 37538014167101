<!-- Base Filter Widget -->
<div *ngIf="visible">
    <div class="filter-container">
    
    </div>
    
    <div class="filter-header">
        FILTERS
        <mat-icon class="filter-close" (click)="onCloseFilterClick()">close</mat-icon>
    </div>
    
    <div class="filter filter-saved">
        <div class="label">SAVED FILTERS</div>
    
        <div class="btn-group" dropdown>
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary field "
                    [ngClass]="{'dropdown-toggle': stateService.savedReportFilters.length > 0}"
                    aria-controls="dropdown-basic"
                    (click)="onFilterSaveClick(false)">
                    {{getFilterSaveDropDownText()}} 
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li *ngFor="let filter of stateService.savedReportFilters" 
                    class="dropdown-item" 
                    (click)="onFilterSaveOptionClick(filter)" 
                    role="menuitem">
                        {{filter.name}}
                        <mat-icon *ngIf="filter.allowDelete" 
                                  class="icon delete-icon"
                                  (click)="onRemoveSavedFilterClick($event, filter)">
                            delete
                        </mat-icon>
                </li>
            </ul>            
        </div>
    
        <div *ngIf="selectedSavedFilterSet" class="field filter-default">
            Make Default

            <mat-slide-toggle 
                class="toggle" 
                [checked]="selectedSavedFilter.default"
                (toggleChange)="onFilterDefaultChanged()">
            </mat-slide-toggle>
        </div>

        <div class="label">ACTIVE FILTERS</div>
    
        <div class="field" 
             style="margin-bottom: 5px;"
             [ngClass]="{'field-expanded' : getFilterText(filter).length > 25}" 
             *ngFor="let filter of activeReportFilters">
            <div class="new" (click)="onActiveFilterClick(filter)">
                <table>
                    <tr>
                        <td width="185px">
                            {{getFilterText(filter)}}
                        </td>
                        <td>
                            <mat-icon *ngIf="!selectedSavedFilterSet" class="icon" (click)="onRemoveFilterClick(filter)">close</mat-icon>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div *ngIf="!selectedSavedFilterSet" class="field" (click)="onAddFilterClick()">
            <div class="new">
                Add Filter
                <mat-icon class="icon">add</mat-icon>
            </div>
        </div>
    </div>
</div>

<!-- Add Filter Widget -->
<div *ngIf="filterEditorVisible">
    <div class="filter-container filter-new">
    
    </div>
    
    <div class="filter-header filter-new">
        {{filterMode}} FILTER
        <mat-icon class="filter-close" (click)="onCloseAddFilterClick()">close</mat-icon>
    </div>

    <div class="filter filter-add">
        <div class="label">FIELD</div>
    
        <div class="btn-group" dropdown>
            <button #filterfield id="filterField" dropdownToggle type="button" class="btn btn-primary dropdown-toggle field"
                    [ngClass]="{'disabled': locked===true}"
                    aria-controls="dropdown-fields">
                {{defaultFieldValue}}    
                <span class="caret"></span>
            </button>
            <ul id="dropdown-fields" *dropdownMenu class="dropdown-menu"
                role="menu" aria-labelledby="filterField">
                <li role="menuitem" *ngFor="let field of report.filters">
                    <a class="dropdown-item" *ngIf="notExistingFilter(field)" (click)="onFilterFieldClick(field)">{{field.name}}</a>
                </li>
            </ul>
        </div>

        <div class="label">OPERATOR</div>
    
        <div class="btn-group" dropdown>
            <button #operatorfield id="operatorField" dropdownToggle type="button" class="btn btn-primary dropdown-toggle field"
                    aria-controls="dropdown-fields">
                {{defaultOperatorValue}}    
                <span class="caret"></span>
            </button>
            <ul id="dropdown-fields" *dropdownMenu class="dropdown-menu"
                role="menu" aria-labelledby="operatorField">
                <li role="menuitem" *ngFor="let operator of operators">
                    <a class="dropdown-item" (click)="onOperatorFieldClick(operator)">{{operator.key}}</a>
                </li>
            </ul>
        </div>

        <div class="label">VALUE</div>
    
        <!-- <ng-container *ngFor="let value of chosenFilter.values; let i=index"> -->
            <ng-container *ngIf="!isSearchableOperator">
                <input *ngFor="let value of chosenFilter.values; let i=index"  
                    [id]="getValueFieldName(i)" 
                    autocomplete="off" 
                    class="filter-value field" 
                    style="margin-bottom: 3px;">
            </ng-container>
            
            <ng-container *ngIf="isSearchableOperator">
                <ngx-select-dropdown *ngFor="let value of chosenFilter.values; let i=index"
                class="field"
                selection=undefined, 
                id="{{getValueFieldName(i)}}" 
                (change)="onDropdownValueSelectionChanged(i, $event)"
                (searchChange)="onDropdownValueSearchChanged(i, $event)"
                [config]="dropdownConfig" 
                [options]="dropdownOptions"
                [selectedItem]="getValueFieldSelectedItem(i)"
                [searchText]="dropdownSearchText">
                </ngx-select-dropdown>
            </ng-container>
            
        <!-- </ng-container> -->
        
        <button mat-button class="filter-add-button" (click)="onFilterApplyClick()">{{filterMode}}</button>
    </div>
</div>
<app-report-filter-new></app-report-filter-new>

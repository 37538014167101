import { Filter, Report, SavedFilter } from './../models/report';
import { Customer } from './../models/customer';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Token } from '../models/token';


@Injectable()
export class CustomerService {

  constructor(private api: ApiService) { }

  getCustomer() {

    // URL from the assets/config.js
    // @ts-ignore
    const url = SERVICE_URL + '/customer';

    return this.api.get(url).then((data)=>{
      return new Promise((resolve, reject) => {
        try
        {
          resolve(new Customer(data['ProcessOutcome']['Customer']));
        }
        catch (e)
        {
          reject(e);
        }
      })
    });
  }  

  getReportDetail(report: Report) {
    return this.api.get(report.detailUrl).then(data=>{
      return new Promise((resolve, reject) => {
        try
        {
          resolve(new Token(data['ProcessOutcome']['ReportDetail']));
        }
        catch (e)
        {
          reject(e);
        }
      })
    });
  }

  setLastLoginDate() {
    // URL from the assets/config.js
    // @ts-ignore
    const url = SERVICE_URL + '/logindate';

    return this.api.post(url, {})
  }

  addReportToFavorites(report: Report) {
    this.api.post(report.favoriteUrl, {});
  }

  removeReportToFavorites(report: Report) {
    this.api.delete(report.favoriteUrl, {});
  }

  setReportLastViewDate(report: Report) {
    this.api.post(report.lastViewUrl, {});
  }


  saveReportFilter(report: Report, filter: SavedFilter) {
    this.api.post(report.filterUrl, filter);
  }

  deleteReportFilter(report: Report, filter: SavedFilter) {
    this.api.delete(report.filterUrl, filter);
  }

  getReportFilterValueList(reportId, filter: Filter, searchText) {
    return this.api.post(filter.filterValueListUrl, {"search": searchText}).then(data=>{
      return new Promise((resolve, reject) => {
        try
        {
          resolve(data['ProcessOutcome']['FilterValues']);
        }
        catch (e)
        {
          reject(e);
        }
      })
    });
  }
}

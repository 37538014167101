<div class="content-container">
  <app-banner 
        [report]="report">
  </app-banner>

  <table class="timeframe" *ngIf="report.powerBi.isTimeBased">
    <tr>
      <td *ngFor="let timeframe of timeframes">
        <button 
          mat-button 
          class="timeframe button" 
          [ngClass]="{'selected': selectedTimeframe===timeframe.value}"
          (click)="onTimeframeClick(timeframe.value)">
          {{timeframe.label}}
        </button>
      </td>
    </tr>
  </table>

  <div class="report-filter" *ngIf="showFilterButton" (click)="onFilterClick()">
    <mat-icon [matBadge]="filterCount" [matBadgeHidden]="filterCount===0">filter_list</mat-icon>
  </div>
  
  <div id="reportContainer" class="report-container" [ngClass]="{'report-container-no-time-frame': report.powerBi.isTimeBased===false}"></div>

  <app-report-filter 
    (close)="onFilterClose()" 
    (filter)="onFilterChange($event)" 
    [report]="report"
    [visible]="showFilter">
  </app-report-filter>

  <!-- <div class="footer">
    <button mat-button class="close-button" (click)="onCloseClick()">CLOSE</button>
  </div> -->
  
</div>
import { BehaviorSubject } from 'rxjs';
import { SelectedMenu } from './../../models/selected-menu';
import { StateService } from 'src/app/services/state.service';
import { Report } from './../../models/report';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

  @Input()
  itemSize: string = 'small';
  
  numReportsPerRow: number = 5;
  gridRows: Report[][] = [];
  filteredReports: Report[] = [];
  lastSelectedMenu: SelectedMenu;

  constructor(private stateService: StateService) {
      
    this.stateService.selectedMenu.subscribe(menu => {
      if (menu === undefined)
      {
        return;
      }

      this.lastSelectedMenu = menu;
      this.filteredReports = this.stateService.getCustomer().reports.filter(f => f.menus.includes(menu.item.id));
      this.loadReportList(this.filteredReports);
    });

    this.stateService.reportReviewRemoved.subscribe(isRemoved => {
      if (isRemoved)
      {
        this.filteredReports = this.stateService.getCustomer().reports.filter(f => f.menus.includes(this.lastSelectedMenu.item.id));
        this.loadReportList(this.filteredReports);
      }
    })
  }

  ngOnInit() {
    console.log('In Report List')
  }

  setItemFavorite() {
    if (this.lastSelectedMenu.item.id === this.stateService.MY_REPORTS)
    {
      this.filteredReports = this.stateService.getCustomer().reports.filter(f => f.menus.includes(this.stateService.MY_REPORTS));
      this.loadReportList(this.filteredReports);
    }
  }

  loadReportList(reports: Report[]) {
    this.gridRows = [];
    const cols: Report[] = [];

    this.stateService.reportListCount.next(reports.length);

    if (reports.length <= 2)
    {
      for (let c=0; c < reports.length; c++)
      {
        cols.push(reports[c]);
      }

      this.gridRows.push(cols);

      return;
    }


    // The reports array must be divisible by numReportsPerRow
    const remainder = reports.length % this.numReportsPerRow;

    if (remainder > 0)
    {
      const padding = this.numReportsPerRow - remainder;

      // any remainder will be added to the reports array
      // as a "blank" report (to make the grid even)
      for (let p=0; p < padding; p++)
      {
        reports.push(new Report({
          id: -1
        }));
      }
    }
    
    for (let c=0; c < reports.length; c++)
    {
      cols.push(reports[c]);
    }

    this.gridRows.push(cols);
  }
}

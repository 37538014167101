export class Menu {
    public id: number;
    public icon: string;
    public title: string;
    public items: MenuItem[] = [];
    
    public constructor(init?: Partial<Menu>) {
        Object.assign(this, init);
    }
}

export class MenuItem {
    public id: number;
    public title: string;

    public constructor(init?: Partial<MenuItem>) {
        Object.assign(this, init);
    }
}
import { Report } from 'src/app/models/report';
import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

interface Rating {
  id: number;
  active: boolean;
}

@Component({
  selector: 'app-report-rating',
  templateUrl: './report-rating.component.html',
  styleUrls: ['./report-rating.component.scss']
})
export class ReportRatingComponent implements OnInit {

  @Input()
  report: Report;

  ratings: Rating[] = [
    {
      id: 1,
      active: false
    },
    {
      id: 2,
      active: false
    },
    {
      id: 3,
      active: false
    },
    {
      id: 4,
      active: false
    },
    {
      id: 5,
      active: false
    }
  ]

  currentRating: number = 0;

  constructor(private modalService: ModalService) { }

  ngOnInit() {
    this.currentRating = this.getCurrentRating();
  }

  getRatingIcon(rating: Rating) {
        
    if (rating.id <= this.currentRating)
    {
      return "star";
    }
   
    if (rating.id - this.currentRating > 0 && rating.id - this.currentRating < 1)
    {
      return "star_half";
    }

    return "star_outline";
  }

  getCurrentRating() {
    return this.report.rating;
  }

  getRatingText() {
    return this.currentRating.toFixed(1) + " (" + this.report.ratingCount + ")";
  }

  onViewRatingClick(event) {
    
    event.stopPropagation();

    this.modalService.open('app-report-rate-viewer', this.report, ()=>{
      
    }, this);
  }
}

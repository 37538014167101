import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  okButtonText: string = "Close";
  moreInfoDialogActive: any;

  @ViewChild('moreInformationTemplate') moreInformationTemplate: TemplateRef<any>;

  constructor(
    public dialogRef: MatDialogRef<NotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {message: string, title?: string, showCancel?: boolean, moreInformation?: string},
    private moreInfoDialog: MatDialog) {
        if (data.showCancel)
        {
          this.okButtonText = "Continue";
        }

        this.dialogRef.disableClose = true;
     }

  ngOnInit() {
  }

  hasMoreInfo(value)
  {
    if (value === '' || value === undefined || value === null)
    {
      return false;
    }

    return true;
  }

  onCancelClick() {
    this.dialogRef.close(false);
  }

  onOkClick() {
    this.dialogRef.close(true);
  }

  onMoreInfoClick() {

    this.moreInfoDialogActive = this.moreInfoDialog.open(this.moreInformationTemplate, {
      width: '300px',
      height: '400px',
      disableClose: true
    });

  }

  onMoreInfoOkClick() {
    this.moreInfoDialogActive.close();
  }
}
import { identifierModuleUrl } from '@angular/compiler';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Rating } from 'src/app/models/report';

interface DistributionItem {
  id,
  count
}
@Component({
  selector: 'app-rate-distribution-widget',
  templateUrl: './rate-distribution-widget.component.html',
  styleUrls: ['./rate-distribution-widget.component.scss']
})
export class RateDistributionWidgetComponent implements OnInit {

  @Input()
  reviews: Rating[] = [];

  @Input()
  numOfPossibleStars: number = 5;

  @Output()
  distributionItemClick = new EventEmitter<number>();


  distribution: DistributionItem[] = [];

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges() {

    this.distribution = [];

    for (let i=1; i <= this.numOfPossibleStars; i++)
    {
      this.distribution.push({
        id: i,
        count: 0
      })
    }

    this.distribution.forEach(item => {
      item.count = this.reviews.filter(r => r.rating === item.id).length;
    });

    this.distribution.sort((a,b) => {
      if (a.id > b.id)
      {
        return -1;
      }

      return 1;
    });
  }

  onItemClick(item: DistributionItem) {
    this.distributionItemClick.emit(item.id);
  }
}

import { Dataset } from 'src/app/models/customer';
import { StateService } from 'src/app/services/state.service';
import { ModalService } from 'src/app/services/modal.service';
import { Component, OnInit } from '@angular/core';

const FREQUENT_THRESHOLD_DAYS = 1;
const FREQUENT_THRESHOLD_DAYS_CRITICAL = 2;
const INTERMEDIATE_THRESHOLD_DAYS = 10;
const INFREQUENT_THRESHOLD_DAYS = 30;
@Component({
  selector: 'app-dataset-viewer',
  templateUrl: './dataset-viewer.component.html',
  styleUrls: ['./dataset-viewer.component.scss']
})
export class DatasetViewerComponent implements OnInit {

  displayInfo: boolean = true;

  columns = [
    {
      columnDef: 'name',
      header: 'Name',
      dataType: 'string',
      cell: (element: Dataset) => `${element.name}`
    },
    {
      columnDef: 'date',
      header: 'Date',
      dataType: 'date',
      cell: (element: Dataset) => element.date === null ? 'No data received' : `${new Date(element.date)}`
    },
  ];

  columnSortName: string = "";
  columnSortDirection: string = ""

  dataSource = this.stateService.getCustomer().datasets;
  displayedColumns = this.columns.map(c => c.columnDef);
  

  constructor(private stateService: StateService, 
              private modalService: ModalService) {
                const display = localStorage.getItem('datasetInfoDisplay');

                if (display === "false")
                {
                  this.displayInfo = false;
                }
              }

  ngOnInit() {
    this.sortData(this.columns[0]);
  }

  closeModal() {
    this.modalService.close('app-dataset-viewer');
  }

  cancel() {
    this.closeModal();
  }

  onDismissInfoClick() {
    this.displayInfo = false;
    localStorage.setItem('datasetInfoDisplay', 'false');
  }

  onRefreshInfoClick() {
    this.displayInfo = true;
    localStorage.setItem('datasetInfoDisplay', 'true');
  }

  getColumnSortIcon(column) {
    if (column.header !== this.columnSortName)
    {
      return '';
    }

    if (this.columnSortDirection === 'Asc')
    {
      return 'arrow_drop_up';
    }

    return 'arrow_drop_down';
  }

  getDataRefreshDate() {
    return this.stateService.getCustomer().refreshdate;
  }

  getStatusTitle(row) {
    const validTitle = "We have received data in the last ?";
    const warnTitle = "We have not received data in the last ?";
    const criticalTitle = "We have not received any data";

    if (row.date === null)
    {
      return criticalTitle;
    }

    var mayBeOkay = ". This may be normal depending on the change frequency of this data in the source system."
    var suffix = "day";

    const indicator = this.getStatusIndicator(row);

    if (indicator === 'valid')
    {
      switch (row.frequency.toLowerCase())
      {
        case "intermediate":
          suffix = INTERMEDIATE_THRESHOLD_DAYS + " days";
          break;
        case "infrequent":
          suffix = INFREQUENT_THRESHOLD_DAYS + " days";
          break;  
      }
    }
    else
    {
      const numberOfDays = Math.floor((Date.now() - Date.parse(row.date)) / 86400000);
      suffix = numberOfDays + (numberOfDays > 1 ? " days" : " day");
    }

    var message = indicator === 'valid' ? validTitle.replace('?', suffix) : warnTitle.replace('?', suffix);

    if ((row.frequency.toLowerCase() === "intermediate" || row.frequency.toLowerCase() === "infrequent") && indicator !== 'valid')
    {
      message += mayBeOkay;
    }

    return message;
  }

  getStatusIndicatorClassName(row) {
    return "update-indicator " + this.getStatusIndicator(row);
  }

  getStatusIndicator(row) {
    const oneDay = 86400000; // Number of milliseconds in a day
    
    const frequentThreshold = oneDay * FREQUENT_THRESHOLD_DAYS;
    const frequentCriticalThreshold = oneDay * FREQUENT_THRESHOLD_DAYS_CRITICAL;
    const intermediateThreshold = oneDay * INTERMEDIATE_THRESHOLD_DAYS;
    const infrequentThreshold = oneDay * INFREQUENT_THRESHOLD_DAYS;
    
    if (row.date === null)
    {
      return "critical";
    }

    const now = new Date();
    const rowDate = new Date(row.date);
    const nowMinusRefreshDate = now.getTime() - rowDate.getTime();

    // Check for critical-frequent first
    if (row.frequency.toLowerCase() === "frequent" && nowMinusRefreshDate > frequentCriticalThreshold)
    {
      return "critical";
    }

    // Default to frequency - Frequent
    var threshold = frequentThreshold;

    switch (row.frequency.toLowerCase())
    {
      case "intermediate":
        threshold = intermediateThreshold;
        break;
      case "infrequent":
        threshold = infrequentThreshold;
        break;
    }

    if (nowMinusRefreshDate > threshold)
    {
      return "warning";
    }

    return "valid";
  }

  sortData(column) {
    const data = this.dataSource.slice();
   
    if (column.header !== this.columnSortName)
    {
      this.columnSortDirection = "Asc";
      this.columnSortName = column.header;
    }
    else
    {
      this.columnSortDirection = this.columnSortDirection === 'Asc' ? 'Dsc' : 'Asc';
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = this.columnSortDirection === 'Asc';
      switch (column.header) {
        case 'Name':
          return this.compare(a.name, b.name, isAsc);
        case 'Date':
          return this.compare(a.date, b.date, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {

    // Is the value a date?
    const dateA = a === null ? new Date().valueOf() : Date.parse(a.toString());
    const dateB = b === null ? new Date().valueOf() : Date.parse(b.toString());
    if (! (isNaN(dateA) && isNaN(dateB)))
    {
      return (dateA < dateB ? -1 : 1) * (isAsc ? 1 : -1);
    }

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  
}

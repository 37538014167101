<div class="report-list">
  <div class="no-reports" *ngIf="filteredReports.length===0">
    <mat-icon class="no-reports-icon">analytics</mat-icon>
  </div>
  
  <div class="container-fluid container-format" *ngIf="filteredReports.length>0">
    <div class="row row-format" *ngFor="let row of gridRows">
      <div class="col col-format" *ngFor="let col of row">
        <div style="min-width: 325px;">
          <app-report-item [report]="col" [size]="itemSize" (favorite)="setItemFavorite()"></app-report-item>
        </div>
      </div>
    </div>
  </div>
</div>

<app-rating-viewer></app-rating-viewer>
import { SettingsComponent } from './components/settings/settings.component';
import { AboutComponent } from './components/about/about.component';
import { ReportContainerComponent } from './components/report-container/report-container.component';
import { ReportListComponent } from './components/report-list/report-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth-guard';

const routes: Routes = [
  { path: '',
    redirectTo: '/reports',
    pathMatch: 'full'
  },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]},
  { path: 'about', component: AboutComponent, canActivate: [AuthGuard]},
  { path: 'reports', component: ReportListComponent, canActivate: [AuthGuard]},
  { path: 'reports/:id', component: ReportContainerComponent, outlet: "fullscreen", canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<aw-modal id="app-report-rater">
  <div class="rate-container">
    <div class="title">RATING & REVIEW</div>

    <div class="rate-widget-container">
      <table class="rate-widget">
        <tr>
          <td>
            <mat-icon 
              *ngFor="let rating of ratings" 
              class="rating-star noselect" 
              (click)="setRating(rating)">
                {{getRatingIcon(rating)}}
            </mat-icon>
          </td>
        </tr>
      </table>
    </div>

    <textarea #commentfield class="form-control rate-note" placeholder="Add a note" rows="4"></textarea>

    <div class="rate-button-container">
      <table>
        <tr>
          <td>
            <button mat-button class="rate-button" (click)="cancel()">CANCEL</button>
          </td>
          <td>
            <button mat-button class="rate-button" (click)="submit()">SUBMIT</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</aw-modal>
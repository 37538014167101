import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortDateTime'
})
export class ShortDateTimePipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (value === null || value === "" || value === undefined)
    {
      return "";
    }

    if (isNaN(Date.parse(value)))
    {
      return value;
    }
    
    //return super.transform(value, 'MM/dd/yyyy hh:mm:ss t').replace('t', new Date(value).getHours() >= 12 ? 'PM' : 'AM');
    return super.transform(value, 'MM/dd/yyyy HH:mm:ss');
  }

}

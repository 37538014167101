<div class="report-container" *ngIf="report.id > -1" [ngClass]="{'large': size==='large'}" (click)="showReport(report)">
    <img [src]="getThumbnail()" class="sample-report" [ngClass]="{'large': size==='large'}">
    <div class="report-like" [ngClass]="{'report-like-large': size==='large'}">
        <mat-icon class="report-like-icon" (click)="setFavorite(true, $event)" *ngIf="!isFavorite()">favorite_border</mat-icon>
        <mat-icon class="report-like-icon" (click)="setFavorite(false, $event)" *ngIf="isFavorite()">favorite</mat-icon>
    </div>
    <div class="report-info">
        <hr>
        <div class="report-title">
            {{report.title}}
        </div>
        <app-report-rating [report]="report"></app-report-rating>
        <div class="report-description">{{report.description}}</div>
        <div class="report-category">
            <hr>
            <div *ngFor="let category of report.categories" class="report-category-item">{{category}}</div>
        </div>
    </div>
</div>
export class PowerBiFilter {
    public name: string;
    public target: PowerBiFilterTarget = new PowerBiFilterTarget();
    
    public constructor(init?: Partial<PowerBiFilter>) {
        Object.assign(this, init);
    }
}

export class PowerBiBasicFilter extends PowerBiFilter {
    public $schema: string = "http://powerbi.com/product/schema#basic";
    public filterType: number = 1;    
    public operator: string;
    public values: any[] = [];
    public requireSingleSelection: boolean = true;

    public constructor(init?: Partial<PowerBiBasicFilter>) {
        super();
        Object.assign(this, init);
    }
}

export class PowerBiAdvancedFilter extends PowerBiFilter {
    public $schema: string = "http://powerbi.com/product/schema#advanced";
    public filterType: number = 0;    
    public logicalOperator: string = "And";
    public conditions: PowerBiFilterCondition[] = [];

    public constructor(init?: Partial<PowerBiAdvancedFilter>) {
        super();
        Object.assign(this, init);
    }
}

export class PowerBiFilterTarget {
    public table: string;
    public column: string;

    public constructor(init?: Partial<PowerBiFilterTarget>) {
        Object.assign(this, init);
    }
}

export class PowerBiFilterCondition {
    public operator: string;
    public value: string;

    public constructor(init?: Partial<PowerBiFilterCondition>) {
        Object.assign(this, init);
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ApiService {

  constructor(private http: HttpClient) { }

  get(endpoint: string) {
    return this.getPromise(endpoint, 'get', {});
  }

  post(endpoint: string, body: object) {
    return this.getPromise(endpoint, 'post', body);
  }

  delete(endpoint: string, body: object) {
    return this.getPromise(endpoint, 'delete', body);
  }

  private getPromise(endpoint: string,
                     verb: string,
                     body: object) {
    return new Promise((resolve, reject) => {

        let config = { 
            headers:
            {
                
            },
            body:
            {
                
            }
        }

      switch(verb) {
        case 'get':
            this.http.get(endpoint, config).subscribe((data: any) => this.handleResponse(resolve, reject, data, endpoint),
            (err) => {
              console.log(err);
              return reject(err);
            });
            break;
        case 'post':
            this.http.post(endpoint, body, config).subscribe((data: any) => this.handleResponse(resolve, reject, data, endpoint),
            (err) => {
              console.log(err);
              return reject(err);
            });
            break;
        case 'delete':
            config.body = body;
            this.http.delete(endpoint, config).subscribe((data: any) => this.handleResponse(resolve, reject, data, endpoint),
            (err) => {
              console.log(err);
              return reject(err);
            });
            break;
      }
    }); 
  }

  handleResponse(resolve, reject, data: any, url: string) {
    
    try
    {
      return resolve(data);
    }
    catch(e)
    {
      return reject(data);
    }

  }
}

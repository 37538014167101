import { CustomerService } from 'src/app/services/customer.service';
import { ModalService } from './../../../services/modal.service';
import { PowerBiFilter, PowerBiBasicFilter, PowerBiAdvancedFilter, PowerBiFilterCondition } from './../../../models/powerbi-filter';
import { NotificationService } from './../../../services/notification.service';
import { StateService } from 'src/app/services/state.service';
import { Filter, ReportFilterValueSearch, SavedFilter } from './../../../models/report';
import { Report } from 'src/app/models/report';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, KeyValueDiffers } from '@angular/core';
import { KeyValue } from '@angular/common';

interface PowerBiFilterData {
  filters: PowerBiFilter[],
  count: number
}

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss']
})
export class ReportFilterComponent implements OnInit {

  

  @ViewChild('filterfield', {static: false}) filterField: ElementRef;
  @ViewChild('operatorfield', {static: false}) operatorField: ElementRef;
  

  @Input()
  report: Report;

  @Input()
  visible: boolean = false;
  
  @Output()
  close: EventEmitter<boolean> = new EventEmitter();

  @Output()
  filter: EventEmitter<PowerBiFilterData> = new EventEmitter();

  fields: string[] = [];
  
  operators: KeyValue<string, string>[] = [
    {
      key: 'is',
      value: 'Is'
    },
    {
      key: 'in',
      value: 'In'
    },
    {
      key: 'contains',
      value: 'Contains'
    },
    {
      key: 'is not',
      value: 'IsNot'
    },
    {
      key: 'starts with',
      value: 'StartsWith'
    },
    {
      key: 'does not start with',
      value: 'DoesNotStartWith'
    },
    {
      key: 'does not contain',
      value: 'DoesNotContain'
    },
    {
      key: 'between',
      value: 'BETWEEN'
    },
    {
      key: 'greater than',
      value: 'GreaterThan'
    },
    {
      key: 'less than',
      value: 'LessThan'
    }
  ];

  // Value drop field setup
  dropdownOptions: any = [];

  dropdownSearchText: string = "";

  dropdownConfig = {
    search: true,
    noResultsFound: 'Searching...',
    searchPlaceholder: 'Search',
    placeholder: 'Choose Value...',
    height: '300px',
    listDisplayFn: this.setDropdownListDisplayText,
    displayFn: this.setDropdownFieldDisplayText
  }

  filterEditorVisible: boolean = false;
  activeReportFilters: Filter[] = [];
  chosenFilter: Filter;
  filterMode: string = "ADD"
  locked: boolean = false;
  selectedSavedFilter: SavedFilter = new SavedFilter();
  selectedSavedFilterSet: boolean = false;

  defaultOperatorValue = 'Choose Operator...';
  defaultFieldValue = 'Choose Field...';

  filterValueSearchTimer: any = 0;
  isSearchableOperator = true;

  MAX_FILTER_TEXT_LENGTH: number = 50;
  MAX_FILTER_COUNT: number = 10;

  constructor(public stateService: StateService,
              private notificationService: NotificationService,
              private modalService: ModalService,
              private customerService: CustomerService) { }

  ngOnInit() {
    
    this.stateService.reportDetail.subscribe(detail => {
      this.activeReportFilters = [];
      this.stateService.clearReportState();

      if (this.stateService.isEmpty(detail) === false && detail.savedFilters.length > 0)
      {
        detail.savedFilters.forEach(filter => {
          this.addFilter(filter);
        });
      }
    });
    
    this.stateService.reportLoaded.subscribe(report => {
      if (this.stateService.isEmpty(report) === false)
      {
        const savedFilterIndex = this.stateService.savedReportFilters.findIndex(f => f.default === true);

        if (savedFilterIndex > -1)
        {
          setTimeout(()=>{
            this.onFilterSaveOptionClick(this.stateService.savedReportFilters[savedFilterIndex]);
          }, 25);
        }
      }
    });
  }

  setDropdownFieldDisplayText(v: ReportFilterValueSearch)
  { 
    let display = (v.code === null ? '' : v.code) + (v.description.length > 0 ?  ' - ' + v.description : ''); 

    if (display.length > 23)
    {
      display = display.substr(0, 23) + '...';
    }

    return display; 
  }

  setDropdownListDisplayText(v: ReportFilterValueSearch)
  {
    if (v.code === null)
    {
      return v.description;
    }

     return v.code + (v.description.length > 0 ?  ' - ' + v.description : ''); 
  }

  onDropdownValueSelectionChanged(fieldIndex, event) {

    let valueElement = this.getValueField(fieldIndex);
    
    // @ts-ignore
    valueElement.selection = event.value;

    if (this.operatorField.nativeElement.innerText === 'in' && this.noEmptyFilterValues(this.chosenFilter)) {
      this.addNewValueField();
    }
  }

  onDropdownValueSearchChanged(fieldIndex, searchText) {
    clearTimeout(this.filterValueSearchTimer)
    this.filterValueSearchTimer = setTimeout(this.doFilterValueSearch.bind(this, fieldIndex, searchText), 750 || 0);
  }

  doFilterValueSearch(fieldIndex, searchText) {
    this.customerService.getReportFilterValueList(this.report.id, this.chosenFilter, searchText).then((values: any) => {
      this.dropdownOptions = values;
      this.dropdownSearchText = searchText;
    });
  }

  onFilterDefaultChanged() {
    this.selectedSavedFilter.default = !this.selectedSavedFilter.default;

    // If the selected filter is now the default, make sure none
    // of the others are set as default
    if (this.selectedSavedFilter.default === true)
    {
      this.stateService.savedReportFilters.map(f => f.id !== this.selectedSavedFilter.id ? f.default = false : f.default = true);
    }

    this.customerService.saveReportFilter(this.report, this.selectedSavedFilter);
  }

  onCloseFilterClick() {
    this.visible = false;
    this.filterEditorVisible = false;
    this.close.emit(true);
  }

  onAddFilterClick() {

    this.filterMode = "ADD";
    this.chosenFilter = new Filter();
    this.filterEditorVisible = true;
    this.locked = false;

    setTimeout(()=>{
      this.filterField.nativeElement.innerText = 'Choose Field...';
      this.operatorField.nativeElement.innerText = 'Choose Operator...';
      this.addFirstValueField();
    }, 10);
  }

  onCloseAddFilterClick() {

    if (this.filterMode === 'UPDATE' && this.hasFilterValue() === false)
    {
      return;
    }

    this.filterEditorVisible = false;
    this.chosenFilter = null;
    this.dropdownOptions = [];
  }

  onActiveFilterClick(filter: Filter) {

    // No filter updates if a saved filter was selected
    if (this.selectedSavedFilterSet)
    {
      return;
    }

    this.filterMode = "UPDATE";
    this.filterEditorVisible = true;

    this.chosenFilter = filter;
    this.locked = true;
    this.dropdownOptions = [];

    this.setSearchableOperator(this.chosenFilter)

    setTimeout(()=>{
      this.filterField.nativeElement.innerText = filter.name;
      this.operatorField.nativeElement.innerText = filter.operator.key;
      
      if (this.isSearchableOperator)
      {
        // Get a list of value options for the selected filter
        this.doFilterValueSearch(0, '');
      }

      for (let i=0; i<filter.values.length; i++)
      {
        let valueElement = this.getValueField(i);
        
        
        if (this.isSearchableOperator)
        {
          // @ts-ignore
          valueElement.selection = filter.values[i];
        }
        else
        {
          // @ts-ignore
          valueElement.value = filter.values[i].id;
        }
        
      }

      if (filter.operator.key === 'in' 
          && this.stateService.isEmpty(filter.values[filter.values.length-1]) === false)
      {
        this.addNewValueField();
      }

    }, 10);
  }

  getValueFieldSelectedItem(fieldIndex) {
    
    const field = this.getValueField(fieldIndex);

    if (this.stateService.isEmpty(field))
    {
      return undefined;
    }

    // @ts-ignore
    return field.selection;
  }

  onRemoveFilterClick(filter: Filter) {
    this.filterEditorVisible = false;
    const filterIndex = this.activeReportFilters.findIndex(f => f === filter);
    this.activeReportFilters.splice(filterIndex, 1);
    filter.values = [];

    this.filter.emit({
      filters: this.getPowerBiFilters(this.activeReportFilters),
      count: this.activeReportFilters.length
    });
  }

  onFilterFieldClick(filter: Filter) {
    filter.values = [];

    // if we are switching fields but the operator was already chosen brforehand then
    // push the chosen operator to the new field
    if (this.stateService.isEmpty(this.chosenFilter) === false)
    {
      filter.operator = this.chosenFilter.operator;
    }

    this.chosenFilter = new Filter(filter);
    this.addFirstValueField();
    this.filterField.nativeElement.innerText = this.chosenFilter.name;

    this.setSearchableOperator(this.chosenFilter)

    if (this.isSearchableOperator)
    {
      // Get a list of value options for the selected filter
      this.doFilterValueSearch(0, '');
    }
  }

  onOperatorFieldClick(operator: KeyValue<string, string>) {
    this.operatorField.nativeElement.innerText = operator.key;

    // If we are switching from one operator to another
    // then reset the dropdown list
    if (this.isSearchableOperator && ! this.stateService.isEmpty(this.chosenFilter.operator))
    {
      this.doFilterValueSearch(0, '');
    }

    this.chosenFilter.operator = operator;
    this.chosenFilter.values = [];
    this.setSearchableOperator(this.chosenFilter);

    const me = this;

    setTimeout(()=>{
      me.addFirstValueField();
    }, 25)
  }

  setSearchableOperator(filter) {
    this.isSearchableOperator = filter.searchable;

    if (filter.operator !== undefined)
    {
      if (filter.operator.key === 'contains' 
      || filter.operator.key === 'starts with' 
      || filter.operator.key === 'does not start with' 
      || filter.operator.key === 'does not contain') {
        this.isSearchableOperator = false;
      }
    }
  }

  onFilterApplyClick() {
    
    if (! this.hasFieldValue())
    {
      return;
    }

    if (! this.hasOperatorValue())
    {
      return;
    }

    // Make sure at least one value was supplied
    if (! this.hasFilterValue())
    {
      return;
    }

    // The chosenFilter property is set via the onFilterFieldClick() function.
    // The chosenOperator property is set via the onOperatorFieldClick() function.
    this.chosenFilter.values = [];
    
    for (let i=0; i<this.MAX_FILTER_COUNT; i++)
    {
      const valueField = this.getValueField(i);

      if (valueField === null)
      {
        break;
      }

      if (this.valueFieldIsEmpty(valueField))
      {
        continue;
      }

      // @ts-ignore
      const value = this.isSearchableOperator ? valueField.selection : {"id": valueField.value, "code": valueField.value, "description": ""};
      this.chosenFilter.values.push(value);
    }

    if (this.filterMode === 'ADD')
    {
      this.activeReportFilters.push(new Filter(this.chosenFilter))
    }
    
    this.filter.emit({
      filters: this.getPowerBiFilters(this.activeReportFilters),
      count: this.activeReportFilters.length
    });
    this.filterEditorVisible = false;
    this.dropdownOptions = [];
  }

  onFilterSaveClick(forceSave: boolean) {

    if (forceSave === false && this.stateService.savedReportFilters.length > 0)
    {
      return;
    }

    if (this.activeReportFilters.length === 0)
    {

      this.notificationService.showAlert("Error", "No filter available to save");
      return;
    }
    
    this.modalService.open('app-report-save-filter', null, (response)=>{
      if (this.stateService.isEmpty(response.name))
      {
        return;
      }
      
      this.stateService.savedReportFilters.map(f => f.selected = false);

      // Remove the 2 static saved report filter options
      // from the ID counter
      const newFilterId = this.stateService.savedReportFilters.length === 0 ? 0 : this.stateService.savedReportFilters.length - 2;

      const newFilter: SavedFilter = {
        id: newFilterId,
        name: response.name, 
        filters: this.activeReportFilters.map(f => f.clone()),
        selected: true,
        allowDelete: true,
        default: false
      };

      this.customerService.saveReportFilter(this.report, newFilter);

      this.addFilter(newFilter);

      this.selectedSavedFilter = newFilter;
    }, this);
    
  }

  addFilter(filter) {
    this.stateService.savedReportFilters.push(filter);
    
    
    if (this.stateService.savedReportFilters.findIndex(f => f.id === 998) === -1)
    {
      this.stateService.savedReportFilters.push({
        id: 998,
        name: 'Clear Filter', 
        filters: [],
        selected: false,
        allowDelete: false,
        default: false
      });
    }

    if (this.stateService.savedReportFilters.findIndex(f => f.id === 999) === -1)
    {
      this.stateService.savedReportFilters.push({
        id: 999,
        name: 'Save New Filter', 
        filters: [],
        selected: false,
        allowDelete: false,
        default: false
      });
    }

    this.stateService.savedReportFilters.sort((a, b) => a.id - b.id);
  }

  onRemoveSavedFilterClick(event, filter)
  {
    event.stopPropagation();

    this.notificationService.showAlert("Remove Filter", "Continue removing filter '" + filter.name + "'?", true, (result)=>{
      if (result === true)
      {
        const index = this.stateService.savedReportFilters.findIndex(f => f.id === filter.id);
        const removedFilter = this.stateService.savedReportFilters.splice(index, 1);
        
        if (removedFilter.length > 0)
        {
          this.customerService.deleteReportFilter(this.report, removedFilter[0]);

          if (this.stateService.isEmpty(this.selectedSavedFilter) === false 
            && this.selectedSavedFilter.id === removedFilter[0].id)
          {
            this.onFilterSaveOptionClick(this.stateService.savedReportFilters.find(f => f.id === 998));
          }
                  
          // If two filters remaining then they must be our two
          // static filters (clear and save). Clear the array.
          if (this.stateService.savedReportFilters.length === 2)
          {
            this.stateService.savedReportFilters = [];
          }
        }
      }
    });
  }

  onFilterSaveOptionClick(event) {

    // Do not allow a "save new filter" event if an existing
    // saved filter is already selected
    if (event.id === 999 && this.stateService.isEmpty(this.selectedSavedFilter.id) === false)
    {

      this.notificationService.showAlert("Error", "No filter available to save");
      return;
    }

    this.selectedSavedFilter = event;

    if (this.selectedSavedFilter.id === 999)
    {
      this.onFilterSaveClick(true);
      return;
    }

    this.stateService.savedReportFilters.map(f => f.selected = false);

    this.selectedSavedFilter.selected = true;
    this.activeReportFilters = this.selectedSavedFilter.filters;
    
    if (this.selectedSavedFilter.id === 998)
    {
      this.selectedSavedFilter = new SavedFilter();
      this.activeReportFilters = [];
    }

    this.filter.emit({
      filters: this.getPowerBiFilters(this.activeReportFilters),
      count: this.activeReportFilters.length
    });
    this.filterEditorVisible = false;
  }

  getFilterSaveDropDownText() {

    this.selectedSavedFilterSet = false;

    if (this.stateService.savedReportFilters.length === 0)
    {
      return "Click to save filter";
    }
    
    var filter = this.stateService.savedReportFilters.find(f => f.selected === true);

    if (this.activeReportFilters.length === 0 || filter === undefined || filter.id === 998)
    {
      return 'Select or save filter';
    }
     
    this.selectedSavedFilterSet = true;
    
    return filter.name;
  }

  notExistingFilter(filter: Filter) {
    return this.activeReportFilters.findIndex(f => f.name === filter.name) === -1;
  }

  getValueFieldName(index: number) {
    return "value" + index;
  }

  getFilterText(filter: Filter) {

    let values = '';

    if (filter.operator.value === 'BETWEEN')
    {
      if (filter.values[0] !== undefined && filter.values[1] !== undefined)
      {
        values = filter.values[0].code + ' and ' + filter.values[1].code;
      }
    }
    else
    {
      filter.values.forEach(v => {
        if (v !== undefined)
        {
          values += v.code + ', ';
        }
      });
  
      if (values.length > 1)
      {
        values = values.substring(0, values.length-2);
      }
    }
   
    if ((values.match(/[A-Z]/g) || []).length > 20)
    {
      values = values.toLowerCase();
    }
    
    const filterText = filter.name + ' ' + filter.operator.key + ' ' + values;

    if (filterText.length > this.MAX_FILTER_TEXT_LENGTH)
    {
      return filterText.substr(0, this.MAX_FILTER_TEXT_LENGTH) + '...'
    }

    return filterText;
  }

  getPowerBiFilters(filters: Filter[]) {

    const powerBiFilters: PowerBiFilter[] = [];

    filters.forEach(filter => {

      switch (filter.operator.value.toLowerCase())
      {
        case 'in':
        case 'is':
          powerBiFilters.push(new PowerBiBasicFilter({
            name: filter.name,
            target: {
              table: filter.table,
              column: filter.column
            },
            operator: filter.operator.value,
            values: this.getPowerBiFilterValues(filter)
          }));
          break;
        case 'between':
          const startCondition: PowerBiFilterCondition[] = [];
          const endCondition: PowerBiFilterCondition[] = [];

          startCondition.push(new PowerBiFilterCondition({
            operator: "GreaterThanOrEqual",
            value: this.getPowerBiFilterValue(filter, 0)
          }));

          endCondition.push(new PowerBiFilterCondition({
            operator: "LessThanOrEqual",
            value: this.getPowerBiFilterValue(filter, 1)
          }));

          powerBiFilters.push(new PowerBiAdvancedFilter({
            name: filter.name,
            target: {
              table: filter.table,
              column: filter.column
            },
            conditions: startCondition
          }));
          
          powerBiFilters.push(new PowerBiAdvancedFilter({
            name: filter.name,
            target: {
              table: filter.table,
              column: filter.column
            },
            conditions: endCondition
          }));

          break;  
        default: 
          const conditions: PowerBiFilterCondition[] = [];

          conditions.push(new PowerBiFilterCondition({
            operator: filter.operator.value,
            value: this.getPowerBiFilterValue(filter, 0)
          }));

          powerBiFilters.push(new PowerBiAdvancedFilter({
            name: filter.name,
            target: {
              table: filter.table,
              column: filter.column
            },
            conditions: conditions
          }));
      }
    });

    return powerBiFilters;
  }

  getPowerBiFilterValues(filter: Filter) {
    return filter.values.map((v: ReportFilterValueSearch)=>{
      if (filter.type === 'key' || filter.type === 'number')
      {
        return parseInt(v.id);
      }

      return v.id;
    })
  }

  getPowerBiFilterValue(filter: Filter, index: number) {
    if (filter.type === 'number')
    {
      return parseInt(filter.values[index].id);
    }
  
    return filter.values[index].id;
  }

  addFirstValueField() {
    
    if (this.chosenFilter.values.length === 0)
    {
      this.chosenFilter.values.push(this.getValueFieldSelectedItem(0));
    }

    // Add a second field for a "between" field
    if (this.stateService.isEmpty(this.chosenFilter.operator) === false 
        && this.chosenFilter.operator.value === 'BETWEEN')
    {
      this.chosenFilter.values.push(this.getValueFieldSelectedItem(1));
    }

    this.setFirstValueFieldFocus();
  }

  addNewValueField() {
    if (this.chosenFilter.values.length < this.MAX_FILTER_COUNT)
    {
      this.chosenFilter.values.push(undefined);
      this.doFilterValueSearch(0, '');
    }
  }

  setFirstValueFieldFocus() {

    setTimeout(() => {
      const valueField = this.getValueField(0);

      if (valueField !== null)
      {
        valueField.focus();
      }
    }, 10)
  }

  hasFieldValue() {
  
    if (this.filterField.nativeElement.innerText === this.defaultFieldValue)
    {
      this.notificationService.showAlert("Filter Error", "Please choose a field",false, ()=>{});
      return false;
    }

    return true;
  }

  hasOperatorValue() {
  
    if (this.operatorField.nativeElement.innerText === this.defaultOperatorValue)
    {
      this.notificationService.showAlert("Filter Error", "Please choose an operator",false, ()=>{});
      return false;
    }

    return true;
  }

  hasFilterValue() {
  
    if (this.chosenFilter.operator.value === 'BETWEEN')
    {
      for (let i=0; i<=1; i++)
      {
        const valueField = this.getValueField(i);
  
        if (valueField === null)
        {
          this.notificationService.showAlert("Filter Error", "Please provide a filter value",false, ()=>{
            //this.chosenFilter.values = [];
            //this.addFirstValueField();
          });
          
          return false;
        }
  
        if (this.valueFieldIsEmpty(valueField))
        {
          this.notificationService.showAlert("Filter Error", "Please provide a filter value",false, ()=>{
            //this.chosenFilter.values = [];
            //this.addFirstValueField();
          });

          return false;
        }
      }

      return true;
    }

    if (! this.filterHasValue())
    {
      this.notificationService.showAlert("Filter Error", "Please provide a filter value",false, ()=>{
        this.chosenFilter.values = [];
        this.addFirstValueField();
      });
      
      return false;
    }

    return true;
  }

  filterHasValue() {

    for (let i=0; i<this.MAX_FILTER_COUNT; i++)
    {
      const valueField = this.getValueField(i);

      if (valueField === null)
      {
        break;
      }

      if (! this.valueFieldIsEmpty(valueField))
      {
        return true;
      }
    }

    return false;
  }


  valueFieldIsEmpty(field) {

    if (field.hasAttribute('selection'))
    {
      return field.innerText === this.dropdownConfig.placeholder;
    }

    return this.stateService.isEmpty(field.value);
  }

  noEmptyFilterValues(filter: Filter) {
    
    for (var i=0; i < filter.values.length; i++)
    {
      if (this.valueFieldIsEmpty(this.getValueField(i)))
      {
        return false;
      } 
    }

    return true;
  }

  getValueField(fieldIndex) {
    return document.getElementById(this.getValueFieldName(fieldIndex));
  }
}
import { Rating, Report } from 'src/app/models/report';
import { ModalService, OpenModalRequest } from './../../services/modal.service';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ReportService } from 'src/app/services/report.service';

interface Star {
  id: number;
  active: boolean;
}

@Component({
  selector: 'app-rater',
  templateUrl: './rater.component.html',
  styleUrls: ['./rater.component.scss']
})
export class RaterComponent implements OnInit {

  @ViewChild('commentfield', {static: false}) commentField: ElementRef;

  @Input()
  report: Report;

  ratings: Star[] = [
    {
      id: 1,
      active: false
    },
    {
      id: 2,
      active: false
    },
    {
      id: 3,
      active: false
    },
    {
      id: 4,
      active: false
    },
    {
      id: 5,
      active: false
    }
  ]

  currentRating: Star = undefined;

  constructor(private modalService: ModalService,
              private reportService: ReportService) {}

  ngOnInit() {
  }

  getRatingIcon(rating: Star) {
    if (rating.active)
    {
      return "star"
    }

    return "star_outline"
  }

  setRating(rating: Star) {
    const index = rating.id;

    // Reset all rating stars to false
    for (let i=0; i<this.ratings.length; i++)
    {
      this.ratings[i].active = false;
    }

    // If we have a rating and the same
    // rating was clicked then set rating
    // to "none"
    if (this.currentRating !== undefined && this.currentRating.id === rating.id)
    {
      this.currentRating = undefined;
      //this.ratingCount--;
      return;
    }
    
    // set all the stars up to the
    // id that was clicked
    for (let i=0; i<index; i++)
    {
      this.ratings[i].active = true;
    }

    //this.ratingCount++;
    this.currentRating = rating;
  }

  closeModal() {
    this.modalService.close('app-report-rater');
  }

  cancel() {
    this.closeModal();
  }

  submit() {
    this.reportService.addReportRating(this.report, new Rating({
      rating: this.currentRating.id,
      comment: this.commentField.nativeElement.value
    }));

    this.currentRating = undefined;

    if (this.commentField !== undefined)
    {
      this.commentField.nativeElement.value = '';
    }
    
    // Reset all rating stars to false
    for (let i=0; i<this.ratings.length; i++)
    {
      this.ratings[i].active = false;
    }

    this.closeModal();
  }

}

import { SettingsComponent } from './components/settings/settings.component';
import { ShortDateTimePipe } from './pipes/short-date-time.pipe';
import { DatasetViewerComponent } from './components/dataset-viewer/dataset-viewer.component';
import { RateWidgetComponent } from './widgets/rate-widget/rate-widget.component';
import { RatingItemComponent } from './components/rating-viewer/rating-item/rating-item.component';
import { RatingViewerComponent } from './components/rating-viewer/rating-viewer.component';
import { ReportService } from './services/report.service';
import { AboutComponent } from './components/about/about.component';
import { ReportFilterNewComponent } from './components/report-container/report-filter/report-filter-new/report-filter-new.component';
import { CustomerService } from './services/customer.service';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationService } from './services/notification.service';
import { ReportFilterComponent } from './components/report-container/report-filter/report-filter.component';
import { RaterComponent } from './components/rater/rater.component';
import { ModalComponent } from './components/modal/modal.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { ReportContainerComponent } from './components/report-container/report-container.component';
import { ReportRatingComponent } from './components/report-item/report-rating/report-rating.component';
import { ReportListComponent } from './components/report-list/report-list.component';
import { ReportItemComponent } from './components/report-item/report-item.component';
import { BannerComponent } from './components/banner/banner.component';
import { MenuComponent } from './components/menu/menu.component';
import { LoginComponent } from './components/login/login.component';
import { StateService } from 'src/app/services/state.service';
import { SettingsService } from './services/settings.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './app-material.module';
import { AuthGuard } from './services/auth-guard';
import { ModalService } from './services/modal.service';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SelectDropDownModule } from './modules/ngx-select-dropdown/ngx-select-dropdown.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { RateDistributionWidgetComponent } from './widgets/rate-distribution-widget/rate-distribution-widget.component';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

 export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources 
 * added to protectedResourceMap. For more info, visit: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(protectedResources.assetAnalyticsApi.endpoint, protectedResources.assetAnalyticsApi.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    BannerComponent,
    ReportItemComponent,
    ReportListComponent,
    ReportRatingComponent,
    ReportContainerComponent,
    ModalComponent,
    RaterComponent,
    ReportFilterComponent,
    ReportFilterNewComponent,
    NotificationComponent,
    AboutComponent,
    RatingViewerComponent,
    RatingItemComponent,
    RateWidgetComponent,
    RateDistributionWidgetComponent,
    DatasetViewerComponent,
    ShortDateTimePipe,
    SettingsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    MsalModule,
    SelectDropDownModule,
    ProgressbarModule.forRoot()
  ],
  exports: [
    ShortDateTimePipe
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    NotificationService,
    AuthGuard,
    StateService,
    ApiService,
    ModalService,
    CustomerService,
    ReportService,
    SettingsService,
    ShortDateTimePipe
  ],
  entryComponents: [NotificationComponent],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

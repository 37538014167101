import { StateService } from 'src/app/services/state.service';
import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
                private stateService: StateService) {

    }

    canActivate(): boolean {

        if (this.stateService.isAuthenticated)
        {
            return true;
        }

        return false;
    }
}
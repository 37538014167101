import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
   imports: [
      CommonModule,
      MatButtonModule,
      MatIconModule,
      MatExpansionModule,
      MatListModule,
      MatSidenavModule,
      MatBadgeModule,
      MatDialogModule,
      MatSlideToggleModule,
      MatTableModule,
      MatTabsModule,
      MatFormFieldModule,
      MatSelectModule,
      MatPaginatorModule
   ],
   exports: [
      CommonModule,
      MatButtonModule,
      MatIconModule,
      MatExpansionModule,
      MatListModule,
      MatSidenavModule,
      MatBadgeModule,
      MatDialogModule,
      MatSlideToggleModule,
      MatTableModule,
      MatTabsModule,
      MatFormFieldModule,
      MatSelectModule,
      MatPaginatorModule
   ],
   providers: [
      
   ]
})

export class AngularMaterialModule { }
import { StateService } from 'src/app/services/state.service';
import { NotificationService } from './../../services/notification.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

interface CodeMapping {
  type: string;
  description: string;
  translation: string;
}

interface MappingOption {
  code: string;
  description: string;
}

interface MappingData {
  code: string;
  description: string;
  translation: string;
}

const MAPPING_DATA: MappingData[] = [];

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit {

  codeMappings: CodeMapping[] = [];
  blankCodeMapping: MappingOption = {code: null, description: "[Clear Translation]"};
  mappingOptions: MappingOption[] = [];

  displayedColumns: string[] = ['description', 'mapping'];
  dataSource = new MatTableDataSource<MappingData>(MAPPING_DATA);

  selectedCodeMapping: CodeMapping = null;
  noData: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  

  constructor(private settingsService: SettingsService,
              private notificationService: NotificationService,
              public stateService: StateService) { }

  ngOnInit() {

    this.settingsService.getTranslationTypes().then((values: any) => {
      this.codeMappings = values;

      this.dataSource.paginator = this.paginator;
    });
  }

  onCodeTypeClick(codeMapping: CodeMapping) {
    
    this.selectedCodeMapping = codeMapping;
    
    this.noData = false;

    this.settingsService.getTranslationOptions(codeMapping.type).then((options: any) => {
      
      this.settingsService.getTranslationValues(codeMapping.type).then((values: any) => {

        if (values.length === 0)
        {
          this.noData = true;
        }

        this.mappingOptions = [];
        this.mappingOptions.push(this.blankCodeMapping);
        this.mappingOptions.push(...options);
        this.dataSource = new MatTableDataSource<MappingData>(values);
        this.dataSource.paginator = this.paginator;
      });
    });

  }

  onTranslationSelected(event, element, value) {
    this.settingsService.setTranslationValue(this.selectedCodeMapping.type, element.code, value).then(result => {
      // @ts-ignore
      if (result.Status != 0)
      {
        this.notificationService.showAlert("Error", "Unable to save translation. Please try again later.", false, ()=>{
          // @ts-ignore
          //event.source.setValue("");
        });
      }
    });
  }

}

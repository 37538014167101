<table class="distribution-container" cellspacing=0 cellpadding=0>
  <tr *ngFor="let item of distribution">
    <td>

      <table class="distribution-item" (click)="onItemClick(item)" cellspacing=0 cellpadding=0>
        <tr>
          <td>
            <span class="star-text">{{item.id}} star</span>
          </td>
          <td>
            <progressbar [value]="item.count" [max]="reviews.length"></progressbar>
          </td>
          <td>
            <span class="star-count">{{item.count}}</span>
          </td>
        </tr>
      </table>

    </td>
  </tr>
</table>
